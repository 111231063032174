import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Filters from "./components/FIlters";
import SearchForm from "./components/SearchForm";
import Table from "./components/Table.js";
import InvitedUsersTools from "./components/tableComponents/InvitedUsersTools";
import Tools from "./components/tableComponents/Tools";
import styles from "./ManageUsers.module.scss";
import NoResultsWavingBox from "../../components/NoResultsWavingBox/NoResultsWavingBox";
import PageContainer from "../../components/PageContainer/PageContainer";
import { HOME_PAGE_ANALYTICS } from "../../constants/analytics";
import { ADD_NEW_USER } from "../../constants/strings";
import {
  fetchAccountUsers,
  fetchInvitedUsers,
  onToggleResendInviteClick,
} from "../../features/user/user.actions";
import { ALL_FILTER, INVITED_FILTER } from "../../features/user/user.constants";
import {
  getEmptyTableSubtitle,
  getEmptyTableTitle,
} from "../../features/user/user.model";
import {
  getUserId,
  getUsersByFilterAndSearch,
} from "../../features/user/user.selectors";
import { CUSTOMER_CREATE_USER_PAGE } from "../../router";
import { getPageLink } from "../../router/navigation";

const ManageUsers = ({
  users,
  emptyTableTitle,
  emptyTableSubtitle,
  onToggleResendInvite,
  currentUserId,
  activeFilter,
  customerId,
  onCreate,
  url,
  onSearch,
  onBlurSearch,
}) => (
  <PageContainer>
    <Grid item container spacing={4} className={styles.search}>
      <Grid item>
        <SearchForm onChange={onSearch} onBlur={onBlurSearch} />
      </Grid>
      <Grid item>
        <Button
          className={styles["add-button"]}
          component={Link}
          onClick={onCreate}
          to={getPageLink(CUSTOMER_CREATE_USER_PAGE, {
            customerId,
          })}
        >
          {ADD_NEW_USER}
        </Button>
      </Grid>
    </Grid>
    <Filters activeFilter={activeFilter} url={url} />

    <Table
      users={users}
      customerId={customerId}
      activeFilter={activeFilter}
      currentUserId={currentUserId}
      ToolsComponent={
        activeFilter === INVITED_FILTER ? InvitedUsersTools : Tools
      }
      onResendInvite={onToggleResendInvite}
      showResendInviteButton={activeFilter === INVITED_FILTER}
      showCreatedDate={activeFilter === INVITED_FILTER}
      emptyTableComponent={
        <NoResultsWavingBox
          title={emptyTableTitle}
          subtitle={emptyTableSubtitle}
        />
      }
    />
  </PageContainer>
);

ManageUsers.propTypes = {
  users: PropTypes.array,
  emptyTableTitle: PropTypes.string,
  emptyTableSubtitle: PropTypes.string,
  currentUserId: PropTypes.string,
  activeFilter: PropTypes.string,
  customerId: PropTypes.string,
  url: PropTypes.string,
  onSearch: PropTypes.func,
  onCreate: PropTypes.func,
  onToggleResendInvite: PropTypes.func,
  onBlurSearch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  users: getUsersByFilterAndSearch(
    ownProps.search,
    ownProps.activeFilter
  )(state),
  currentUserId: getUserId(state),
});

const mapDispatchToProps = (dispatch, { customerId }) => ({
  onFetchAccountUsers: () => dispatch(fetchAccountUsers(customerId)),
  onFetchInvitedUsers: () => dispatch(fetchInvitedUsers(customerId)),
  onToggleResendInvite: (id) => dispatch(onToggleResendInviteClick(id)),
});

export default compose(
  withState("search", "setSearch", ""),
  withProps((props) => {
    const activeFilter = props.location.search.split("=")[1] || ALL_FILTER;
    return {
      activeFilter,
      emptyTableTitle: getEmptyTableTitle(activeFilter, props.search),
      emptyTableSubtitle: getEmptyTableSubtitle(activeFilter, props.search),
      customerId: props.match.params.customerId,
      url: props.match.url,
    };
  }),
  withHandlers({
    onSearch:
      (props) =>
      ({ search = "" }) => {
        if (search.length > 2 || !search.length) {
          props.setSearch(search);
        }
      },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrack({
    loadId: HOME_PAGE_ANALYTICS.LOAD,
    interfaceId: HOME_PAGE_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onBlurSearch: () => HOME_PAGE_ANALYTICS.SEARCH_TEXT_ENTRY,
    onCreate: () => HOME_PAGE_ANALYTICS.NEW_USER,
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.activeFilter === INVITED_FILTER) {
        this.props.onFetchInvitedUsers();
      } else {
        this.props.onFetchAccountUsers();
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.activeFilter !== this.props.activeFilter ||
        prevProps.customerId !== this.props.customerId
      ) {
        if (this.props.activeFilter === INVITED_FILTER) {
          this.props.onFetchInvitedUsers();
        } else {
          this.props.onFetchAccountUsers();
        }
      }
    },
  })
)(ManageUsers);
