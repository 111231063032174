import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import { Field, isValid, reduxForm } from "redux-form";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./SearchForm.module.scss";
import DataTable from "./Table";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { CUSTOMER_MIGRATIONS_ANALYTICS } from "../../../../constants/analytics";
import { CUSTOMER_MIGRATION_SEARCH_FORM } from "../../../../constants/forms";
import {
  ACCOUNT_NUMBER,
  BATCH_ACCOUNTS,
  COMPANY_NAME,
  NO_RESULTS_FOUND,
  SEARCH,
  SEARCH_TERM,
  SYNC,
  SYNC_MYDPD_APP,
  SYNC_MYDPD_LOCAL_APP,
} from "../../../../constants/strings";
import { isDpdLocalDomain } from "../../../../features/app/app.modes";
import { getDpdAppVersions } from "../../../../features/config/config.selectors";
import {
  clearSearchField,
  onSearch,
  onSyncVersion,
} from "../../../../features/migrationSearch/search.actions";
import {
  CUSTOMER_COLUMS,
  SEARCH_PARAM_FIELD,
  SEARCH_PARAMS,
  SEARCH_RESULTS_FIELD,
  SEARCH_VALUE_FIELD,
  USER_COLUMS,
  VERSION_FIELD,
} from "../../../../features/migrationSearch/search.constants";
import { getInitialSearchFormValues } from "../../../../features/migrationSearch/search.models";
import {
  getSearchMigrationResults,
  getSearchParam,
  getSearchValue,
  isFormSubmitted,
} from "../../../../features/migrationSearch/search.selectors";
import { validate } from "../../../../features/migrationSearch/search.validate";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const SearchForm = ({
  searchValue,
  onSearchClick,
  onFocusDropdown,
  searchParam,
  handleSubmit,
  isFormValid,
  isSubmitted,
  onClear,
  onChange,
  searchResults = [],
  appVersions = [],
  onFocusVersionDropdown,
  onChangeVersion,
  onSyncClick,
  onEnterClick,
}) => (
  <Grid className={styles.container}>
    <Grid md={12} container justify="space-between" alignItems="center">
      <Grid item md={10} sm={12}>
        <Grid md={12} container justify="center" alignItems="center">
          <Grid item md={6} sm={12} className={styles.item}>
            <Field
              component={SelectWithTitle}
              name={SEARCH_PARAM_FIELD}
              onChange={onChange}
              onFocus={onFocusDropdown}
            >
              <MenuItem value={SEARCH_PARAMS.ACCOUNT}>
                <SelectItem title={ACCOUNT_NUMBER} />
              </MenuItem>
              <MenuItem value={SEARCH_PARAMS.ACCOUNTS}>
                <SelectItem title={BATCH_ACCOUNTS} />
              </MenuItem>
              <MenuItem value={SEARCH_PARAMS.COMPANY_NAME}>
                <SelectItem title={COMPANY_NAME} />
              </MenuItem>
            </Field>
          </Grid>
          <Grid item md={6} sm={12} className={styles.item}>
            <Field
              name={SEARCH_VALUE_FIELD}
              placeholder={SEARCH_TERM}
              component={DecoratedFieldWithError}
              onClearClick={onClear}
              inputProps={{
                maxLength: searchParam === SEARCH_PARAMS.ACCOUNT ? 7 : 250,
              }}
              disabled={isSubmitted && isFormValid && searchValue}
              onKeyDown={(e) => e.keyCode === 13 && onEnterClick()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2} sm={12} className={styles.submit}>
        <Button
          size="small"
          type="submit"
          onClick={handleSubmit(onSearchClick)}
          disabled={!searchValue || !isFormValid}
          className={classNames(
            (!searchValue || !isFormValid) && styles.disabled
          )}
        >
          <span className={styles.buttonText}>{SEARCH}</span>
        </Button>
      </Grid>
    </Grid>
    <Grid container justify="space-between" alignItems="center">
      {searchParam === SEARCH_PARAMS.ACCOUNT && searchResults.length ? (
        <Field
          name={SEARCH_RESULTS_FIELD}
          component={DataTable}
          columns={USER_COLUMS}
        />
      ) : null}
      {searchParam !== SEARCH_PARAMS.ACCOUNT && searchResults.length ? (
        <Field
          name={SEARCH_RESULTS_FIELD}
          component={DataTable}
          columns={CUSTOMER_COLUMS}
        />
      ) : null}
    </Grid>
    {!searchResults.length && isSubmitted && isFormValid && searchValue ? (
      <Typography className={styles.description} color={"primary"}>
        {NO_RESULTS_FOUND}
      </Typography>
    ) : null}
    {searchResults.length && appVersions.length ? (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ paddingTop: "32px" }}
      >
        <Grid item>
          <Typography className={styles.description}>
            {isDpdLocalDomain() ? SYNC_MYDPD_LOCAL_APP : SYNC_MYDPD_APP}
          </Typography>
        </Grid>
        <Grid item style={{ width: "243px" }}>
          <Field
            component={SelectWithTitle}
            name={VERSION_FIELD}
            onChange={onChangeVersion}
            onFocus={onFocusVersionDropdown}
          >
            {appVersions.map((release) => (
              <MenuItem key={release.version} value={release.version}>
                <SelectItem
                  containerStyle={classNames(
                    release.prerelease && styles.prerelease
                  )}
                  title={`Move to ${release.version} ${
                    release.prerelease ? "(PRERELEASE)" : ""
                  }`}
                />
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item>
          <Button
            size="small"
            type="submit"
            onClick={onSyncClick}
            disabled={!searchValue || !isFormValid}
            className={classNames(
              (!searchValue || !isFormValid) && styles.disabled
            )}
          >
            <span className={styles.buttonText}>{SYNC}</span>
          </Button>
        </Grid>
      </Grid>
    ) : null}
  </Grid>
);

SearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  searchValue: PropTypes.string,
  searchResults: PropTypes.object,
  onSearchClick: PropTypes.func,
  onSearch: PropTypes.func,
  onFocusDropdown: PropTypes.func,
  onEnterClick: PropTypes.func,
  onSyncClick: PropTypes.func,
  onChange: PropTypes.func,
  onChangeVersion: PropTypes.func,
  onClear: PropTypes.func,
  onFocusVersionDropdown: PropTypes.func,
  isFormValid: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  searchParam: PropTypes.string,
  appVersions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  searchResults: getSearchMigrationResults(state),
  searchValue: getSearchValue(state),
  appVersions: getDpdAppVersions(state),
  searchParam: getSearchParam(state),
  isSubmitted: isFormSubmitted(state),
  isFormValid: isValid(CUSTOMER_MIGRATION_SEARCH_FORM)(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSearchClick: (formValues) => dispatch(onSearch(formValues)),
  onSyncClick: (formValues) => dispatch(onSyncVersion(formValues)),
  onClear: () => dispatch(clearSearchField()),
});

export default compose(
  reduxForm({
    form: CUSTOMER_MIGRATION_SEARCH_FORM,
    initialValues: getInitialSearchFormValues,
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState("selectedRows", "setSelectedRows", []),
  withTrackProps((props) => ({
    onEnterClick: () => props.handleSubmit(props.onSearchClick)(),
    onSearchClick: CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_SEARCH,
    onSyncClick: CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_SYNC,
    onChange: () => {
      props.onClear();
      return CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_SEARCH_DROPDOWN_ITEM;
    },
    onChangeVersion:
      CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_VERSION_DROPDOWN_ITEM,
    onFocusVersionDropdown:
      CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_VERSION_DROPDOWN,
    onFocusDropdown: CUSTOMER_MIGRATIONS_ANALYTICS.ON_CLICK_SEARCH_DROPDOWN,
  }))
)(SearchForm);
