import omit from "lodash/omit";

export const LOGIN_FORM = "LOGIN_FORM";
export const LOGIN_PROVIDER_FORM = "LOGIN_PROVIDER_FORM";
export const USER_FORM = "USER_FORM";
export const SET_PASSWORD_FORM = "SET_PASSWORD_FORM";
export const USER_DETAILS = "USER_DETAILS_FORM";
export const LOGIN_EMAIL_FORM = "LOGIN_EMAIL_FORM";
export const CREATE_DPD_USER_FORM = "CREATE_DPD_USER_FORM";
export const DPD_SEARCH_FORM = "DPD_SEARCH_FORM";
export const CUSTOMER_MIGRATION_SEARCH_FORM = "CUSTOMER_MIGRATION_SEARCH_FORM";
export const SEARCH_PROFILE_FORM = "SEARCH_PROFILE_FORM";
export const CREATE_PROFILE_FORM = "CREATE_PROFILE_FORM";
export const SLIDS_FORM = "SLIDS_FORM";
export const SEARCH_FORM = "SEARCH_FORM";

export const USER_FORMS = {
  DPD_USER_DETAILS: "DPD_USER_DETAILS",
  ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
  ADDITIONAL_ACCOUNTS: "ADDITIONAL_ACCOUNTS",
  SYSTEM_ACCESS: "SYSTEM_ACCESS",
  SYSTEM_ACCESS_ACCOUNTS: "SYSTEM_ACCESS_ACCOUNTS",
  MISCELLANEOUS_CONFIGURATION: "MISCELLANEOUS_CONFIGURATION",
  ADVANCED_CUSTOMER_CONFIGURATION: "ADVANCED_CUSTOMER_CONFIGURATION",
  SHIPPING_SETTINGS: "SHIPPING_SETTINGS",
  SECURITY_SETTINGS: "SECURITY_SETTINGS",
  XREF_MAPPINGS: "XREF_MAPPINGS",
  SERVICE_EXCLUSIONS: "SERVICE_EXCLUSIONS",
  SERVICE_RESTRICTIONS: "SERVICE_RESTRICTIONS",
  USER_PROFILES: "USER_PROFILES",
  IP_RESTRICTIONS: "IP_RESTRICTIONS",
};

export const USER_DETAILS_FORMS = omit(USER_FORMS, USER_FORMS.IP_RESTRICTIONS);

export const TEAM_SETTINGS_FORM = "TEAM_SETTINGS_FORM";
export const CREATE_MASTER_USER_FORM = "CREATE_MASTER_USER_FORM";
export const IP_ADDRESS_FORM = "IP_ADDRESS_FORM";
export const IP_RANGE_FORM = "IP_RANGES_FORM";
