import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Filter from "./Filter";
import styles from "./Filters.module.scss";
import { HOME_PAGE_ANALYTICS } from "../../../constants/analytics";
import {
  ALL,
  INACTIVE,
  INVITED_USERS,
  SUSPENDED,
} from "../../../constants/strings";
import {
  ALL_FILTER,
  INACTIVE_FILTER,
  INVITED_FILTER,
  SUSPENDED_FILTER,
} from "../../../features/user/user.constants";
import {
  ALL_USERS_FILTER,
  INACTIVE_USERS_FILTER,
  INVITED_USERS_FILTER,
  SUSPENDED_USERS_FILTER,
} from "../../../router/constants";

const Filters = ({
  activeFilter,
  url,
  onClickInvitedUsers,
  onClickAllUsers,
  onClickInactiveUsers,
  onClickSuspendedUsers,
}) => (
  <Grid container item className={styles.filters}>
    <Grid item>
      <Filter
        text={ALL}
        onClick={onClickAllUsers}
        active={activeFilter === ALL_FILTER}
        to={url + ALL_USERS_FILTER}
      />
    </Grid>
    <Grid>
      <Filter
        text={SUSPENDED}
        onClick={onClickSuspendedUsers}
        active={activeFilter === SUSPENDED_FILTER}
        to={url + SUSPENDED_USERS_FILTER}
      />
    </Grid>
    <Grid>
      <Filter
        text={INACTIVE}
        onClick={onClickInactiveUsers}
        active={activeFilter === INACTIVE_FILTER}
        to={url + INACTIVE_USERS_FILTER}
      />
    </Grid>
    <Grid>
      <Filter
        text={INVITED_USERS}
        onClick={onClickInvitedUsers}
        active={activeFilter === INVITED_FILTER}
        to={url + INVITED_USERS_FILTER}
      />
    </Grid>
  </Grid>
);

Filters.propTypes = {
  users: PropTypes.array,
  emptyTableTitle: PropTypes.string,
  emptyTableSubtitle: PropTypes.string,
  activeFilter: PropTypes.string,
  customerId: PropTypes.string,
  url: PropTypes.string,
  onSearch: PropTypes.func,
  onClickInvitedUsers: PropTypes.func,
  onClickAllUsers: PropTypes.func,
  onClickInactiveUsers: PropTypes.func,
  onClickSuspendedUsers: PropTypes.func,
};

export default compose(
  withTrackProps(() => ({
    onClickInvitedUsers: () => HOME_PAGE_ANALYTICS.CLICK_FILTER__INVITED_USERS,
    onClickAllUsers: () => HOME_PAGE_ANALYTICS.CLICK_FILTER__ALL,
    onClickInactiveUsers: () => HOME_PAGE_ANALYTICS.CLICK_FILTER__INACTIVE,
    onClickSuspendedUsers: () => HOME_PAGE_ANALYTICS.CLICK_FILTER__SUSPENDED,
  }))
)(Filters);
