import { get, isNull, omit } from "lodash";
import qs from "query-string";

import {
  ACCOUNT_INTERNATIONAL_TAB_FIELDS,
  CREATE_ACCOUNT_NUMBER_FIELD,
  DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD,
  DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_WEIGHT_FIELD,
  DEFAULTS_COMMON_FIELDS,
  DEFAULTS_DPD_TAB_FIELDS,
  DEFAULTS_LOCAL_DPD_TAB_FIELDS,
  DELETE_PREFIX,
  DPD_ACCOUNT_LENGTH,
  DPD_DOMESTIC_OUTBOUND_SLID_FIELD,
  DPD_INBOUND_SLID_FIELD,
  DPD_OUTBOUND_SLID_FIELD,
  INTERNATIONAL_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_BRAND_FIELD,
  INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_CLASSIC_SLID_FIELD,
  INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_DIRECT_SLID_FIELD,
  INTERNATIONAL_DPD_FIELD,
  INTERNATIONAL_DPD_LOCAL_FIELD,
  INTERNATIONAL_EXPRESS_SLID_FIELD,
  INV_DEFAULT_CURRENCY_FIELD,
  INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD,
  INV_EXPORT_INVOICE_REQUIRED_FIELD,
  INV_INVOICE_TYPE_FIELD,
  PROFILE_CODE_FIELD,
  PROFILE_NAME_FIELD,
  SERVICES_FIELDS,
  TOGGLE_PROFILE_FIELDS,
  USE_MY_DPD_ACCOUNT_SETTINGS_FIELD,
} from "./profile.constants";
import {
  ACCOUNT_NUMBER,
  CLASSIC_ACCOUNT_NUMBER,
  DIRECT_ACCOUNT_NUMBER,
  DOMESTIC_OUTBOUND_SERVICE,
  EXCHANGE_OUTBOUND_SERVICE,
  EXCHANGE_RETURN_SERVICE,
  EXPRESS_ACCOUNT_NUMBER,
  NONE,
  OFFSHORE_EXCHANGE_SERVICE,
  OFFSHORE_OUTBOUND_SERVICE,
  OUTBOUND_SERVICE,
  PROFILE_CODE,
  PROFILE_NAME,
} from "../../constants/strings";
import {
  filterExchangeServices,
  filterInternationalService,
  filterLocalOutboundServices,
  filterOutboundServices,
} from "../config/config.models";
import { ADMIN_ACCOUNT } from "../customer/customer.constants";

export const createData = (profileCode, customerID, ...fields) => {
  const tableFields = fields.reduce((acc, value, key) => {
    acc[`field${key}`] = value;
    return acc;
  }, {});
  return { id: profileCode, account: customerID, fields: tableFields };
};

export const getQueryStringParams = (query) => qs.parse(query);

export const prepareSearchTableData = (data) =>
  data.map(({ profileCode, profileName, customerID }) =>
    createData(
      profileCode,
      customerID,
      profileCode,
      profileName,
      customerID,
      `${DELETE_PREFIX}${profileCode}`
    )
  );

const getProfileInitialValues = () => ({
  [INTERNATIONAL_DPD_FIELD]: true,
  [INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD]: 1,
  [INTERNATIONAL_BRAND_FIELD]: "1",
  [INV_EXPORT_INVOICE_REQUIRED_FIELD]: "S",
  [INV_INVOICE_TYPE_FIELD]: "S",
});

export const getInitialValues = () => {
  const initialValues = getProfileInitialValues();

  TOGGLE_PROFILE_FIELDS.forEach((field) => {
    initialValues[field] = true;
  });
  initialValues[INV_DEFAULT_CURRENCY_FIELD] = NONE;
  initialValues[USE_MY_DPD_ACCOUNT_SETTINGS_FIELD] = true;

  return initialValues;
};

export const formMapper = (formValues, duplicate, config, currencies) => {
  const initialValues = getProfileInitialValues();
  const formKeys = Object.keys(formValues);
  formKeys.forEach((fieldName) => {
    if (
      fieldName === DEFAULT_WEIGHT_FIELD &&
      !formValues[DEFAULT_WEIGHT_FIELD]
    ) {
      initialValues[fieldName] = "";
    } else if (
      [...TOGGLE_PROFILE_FIELDS, USE_MY_DPD_ACCOUNT_SETTINGS_FIELD].includes(
        fieldName
      )
    ) {
      initialValues[fieldName] = Number(get(formValues, fieldName)) || 0;
    } else if (SERVICES_FIELDS.includes(fieldName)) {
      initialValues[fieldName] = getServiceInitialValue(
        config,
        fieldName,
        get(formValues, fieldName)
      );
    } else if (fieldName === INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD) {
      if (isNull(formValues[INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD])) {
        initialValues[fieldName] = 1;
      } else {
        initialValues[fieldName] = formValues[
          INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD
        ]
          ? 1
          : 2;
      }
    } else {
      initialValues[fieldName] = String(get(formValues, fieldName, ""));
    }
  });
  initialValues[INV_DEFAULT_CURRENCY_FIELD] = getInitialCurrencyValue(
    formValues,
    currencies
  );
  initialValues[INTERNATIONAL_DPD_FIELD] =
    initialValues[INTERNATIONAL_BRAND_FIELD] !== "2";
  initialValues[INTERNATIONAL_DPD_LOCAL_FIELD] =
    initialValues[INTERNATIONAL_BRAND_FIELD] === "2";

  if (duplicate) {
    initialValues[PROFILE_CODE_FIELD] = "";
    initialValues[PROFILE_NAME_FIELD] = "";
  }

  return initialValues;
};

const getValue = (services, value) => {
  const currentService = services.find((service) => service.code === value);
  return currentService ? value : "";
};

const getServiceInitialValue = (config, fieldName, value) => {
  switch (fieldName) {
    case DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD: {
      const internationalServices = filterInternationalService(
        config.dpdServices
      );
      return getValue(internationalServices, value);
    }
    case DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD:
    case DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD: {
      const services = filterLocalOutboundServices(config.dpdLocalServices);
      return getValue(services, value);
    }
    case DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD:
    case DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD: {
      const services = filterOutboundServices(config.dpdServices);
      return getValue(services, value);
    }
    case DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD:
    case DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD:
    case DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD: {
      const services = filterExchangeServices(config.dpdServices);
      return getValue(services, value);
    }
    case DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD: {
      const services = filterInternationalService(config.dpdLocalServices);
      return getValue(services, value);
    }
  }
};

const getInitialCurrencyValue = (formValues, currencies) => {
  const settingCurrency = get(formValues, INV_DEFAULT_CURRENCY_FIELD, "");
  const currentCurrency = currencies.find(
    (value) => value.currencyCode === settingCurrency
  );
  return currentCurrency ? currentCurrency.currencyCode : NONE;
};

export const prepareCreateProfileData = (formValues) => {
  const omitFields = [INTERNATIONAL_DPD_FIELD, INTERNATIONAL_DPD_LOCAL_FIELD];
  const invoiceType = formValues[INV_INVOICE_TYPE_FIELD];
  const exportInvoiceType = formValues[INV_EXPORT_INVOICE_REQUIRED_FIELD];
  const useMyDpdAccountSettings = formValues[USE_MY_DPD_ACCOUNT_SETTINGS_FIELD];

  if (formValues[INTERNATIONAL_DPD_FIELD]) {
    omitFields.push(DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD);
    formValues[INTERNATIONAL_BRAND_FIELD] = "1";
  }

  if (formValues[INTERNATIONAL_DPD_LOCAL_FIELD]) {
    omitFields.push(DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD);
    formValues[INTERNATIONAL_BRAND_FIELD] = "2";
  }

  !invoiceType && omitFields.push(INV_INVOICE_TYPE_FIELD);

  !exportInvoiceType && omitFields.push(INV_EXPORT_INVOICE_REQUIRED_FIELD);

  TOGGLE_PROFILE_FIELDS.forEach((field) => {
    if (formValues[field]) {
      formValues[field] = Number(formValues[field]);
    } else {
      omitFields.push(field);
    }
  });

  useMyDpdAccountSettings
    ? (formValues[USE_MY_DPD_ACCOUNT_SETTINGS_FIELD] = 1)
    : omitFields.push(USE_MY_DPD_ACCOUNT_SETTINGS_FIELD);

  Object.keys(formValues).forEach((fieldName) => {
    if (!formValues[fieldName]) {
      omitFields.push(fieldName);
    }
  });

  const profileData = omit(formValues, omitFields);

  profileData[INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD] = Number(
    get(profileData, INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD, 1)
  );

  profileData[CREATE_ACCOUNT_NUMBER_FIELD] = Number(
    profileData[CREATE_ACCOUNT_NUMBER_FIELD]
  );

  return profileData;
};

export const prepareSlids = (formValues) => {
  const slidFields = [
    DPD_DOMESTIC_OUTBOUND_SLID_FIELD,
    DPD_OUTBOUND_SLID_FIELD,
    DPD_INBOUND_SLID_FIELD,
    INTERNATIONAL_EXPRESS_SLID_FIELD,
    INTERNATIONAL_CLASSIC_SLID_FIELD,
    INTERNATIONAL_DIRECT_SLID_FIELD,
  ];

  return slidFields.reduce((acc, fieldName) => {
    const seqName = formValues[fieldName];
    const shouldAddSlid =
      seqName && !acc.find((item) => item.seqName === seqName);

    shouldAddSlid && acc.push({ seqName });

    return acc;
  }, []);
};

export const checkShouldShowProfileDefaultsDpdBadge = (
  isFormSubmissionFailed,
  errors
) =>
  Object.keys(errors).some((field) =>
    DEFAULTS_DPD_TAB_FIELDS.includes(field)
  ) && isFormSubmissionFailed;

export const checkShouldShowProfileDefaultsBadge = (
  isFormSubmissionFailed,
  errors
) =>
  Object.keys(errors).some((field) => DEFAULTS_COMMON_FIELDS.includes(field)) &&
  isFormSubmissionFailed;

export const checkIfInternationalFieldsValid = (errors) =>
  Object.keys(errors).some((field) =>
    ACCOUNT_INTERNATIONAL_TAB_FIELDS.includes(field)
  );

export const defaultsRequiredFields = {
  [DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD]: OUTBOUND_SERVICE,
  [DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD]: EXCHANGE_OUTBOUND_SERVICE,
  [DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD]: EXCHANGE_RETURN_SERVICE,
  [DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD]: OFFSHORE_OUTBOUND_SERVICE,
  [DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD]: OFFSHORE_EXCHANGE_SERVICE,
};

export const fieldLabels = {
  [CREATE_ACCOUNT_NUMBER_FIELD]: ACCOUNT_NUMBER,
  [PROFILE_CODE_FIELD]: PROFILE_CODE,
  [PROFILE_NAME_FIELD]: PROFILE_NAME,
  [INTERNATIONAL_ACCOUNT_NUMBER_FIELD]: EXPRESS_ACCOUNT_NUMBER,
  [INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD]: CLASSIC_ACCOUNT_NUMBER,
  [INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD]: DIRECT_ACCOUNT_NUMBER,
};

export const checkShouldShowProfileDefaultsLocalDpdBadge = (
  isFormSubmissionFailed,
  errors
) =>
  Object.keys(errors).some((field) =>
    DEFAULTS_LOCAL_DPD_TAB_FIELDS.includes(field)
  ) && isFormSubmissionFailed;

export const defaultsDpdLocalRequiredFields = {
  [DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD]:
    OFFSHORE_OUTBOUND_SERVICE,
  [DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD]:
    DOMESTIC_OUTBOUND_SERVICE,
};

export const isLocalDpdAccount = (accountNumber = "") =>
  accountNumber.length &&
  accountNumber.length !== DPD_ACCOUNT_LENGTH &&
  accountNumber !== ADMIN_ACCOUNT;
