import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import styles from "./CreateUserForm.module.scss";
import { ReactComponent as User } from "../../../../assets/icons/username.svg";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SvgIconComponent from "../../../../components/SvgIcon/svgIcon";
import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle.js";
import {
  EMAIL_ADDRESS,
  FIRST_NAME,
  PERMISSION_CREATE_DPD_USER,
  PERMISSION_CREATE_MASTER_USER,
  SURNAME,
} from "../../../../constants/strings.js";
import {
  CREATE_PERMISSION_FIELD,
  FORENAME_FIELD,
  LASTNAME_FIELD,
  PERMISSION_TO_CREATE_MASTER_USERS,
} from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const CreateUserForm = ({ onToggleChange }) => (
  <form className={styles.container}>
    <Grid container className={styles["profile-container"]}>
      <Grid item className={styles.input}>
        <Field
          name={FORENAME_FIELD}
          component={DecoratedFieldWithError}
          label={FIRST_NAME}
          icon={<User />}
          inputProps={{ maxLength: 35 }}
          required
        />
      </Grid>
      <Grid item className={styles.input}>
        <Field
          name={LASTNAME_FIELD}
          component={DecoratedFieldWithError}
          label={SURNAME}
          icon={<User />}
          inputProps={{ maxLength: 35 }}
          required
        />
      </Grid>
      <Grid item className={styles.input}>
        <Field
          name={"email"}
          component={DecoratedFieldWithError}
          label={EMAIL_ADDRESS}
          icon={<SvgIconComponent name="email" />}
          inputProps={{ maxLength: 100 }}
          required
        />
      </Grid>
    </Grid>
    <Grid container className={styles["role-container"]}>
      <Field
        name={CREATE_PERMISSION_FIELD}
        component={CustomSwitchWithTitle}
        type="checkbox"
        onChange={onToggleChange}
        label={
          <span className={styles.label}>{PERMISSION_CREATE_DPD_USER}</span>
        }
      />
      <Field
        name={PERMISSION_TO_CREATE_MASTER_USERS}
        component={CustomSwitchWithTitle}
        type="checkbox"
        onChange={onToggleChange}
        label={
          <span className={styles.label}>{PERMISSION_CREATE_MASTER_USER}</span>
        }
      />
    </Grid>
  </form>
);

CreateUserForm.propTypes = {
  onToggleChange: PropTypes.func,
};

export default CreateUserForm;
