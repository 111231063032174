import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import SearchInput from "../../../components/SearchInput/SearchInput";
import { SEARCH_FORM } from "../../../constants/forms";
import { SEARCH_FIELD } from "../../../features/user/user.constants";

function SearchForm({ onBlur }) {
  return (
    <Field
      name={SEARCH_FIELD}
      component={SearchInput}
      type="text"
      onBlur={onBlur && onBlur}
    />
  );
}

SearchForm.propTypes = {
  onBlur: PropTypes.func,
};

export default reduxForm({ form: SEARCH_FORM })(SearchForm);
