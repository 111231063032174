export const USERNAME_FIELD = "username";
export const ACCOUNTS_FIELD = "accounts";
export const FORENAME_FIELD = "forename";
export const LASTNAME_FIELD = "lastname";
export const DESCRIPTION_FIELD = "description";
export const AUTOGENERATED_USERNAME_FIELD = "autogeneratedUsername";
export const POSITION_FIELD = "position";
export const TITLE_FIELD = "title";
export const PHONE_FIELD = "phone";
export const PASSWORD_LOCK_FIELD = "passwordLock";
export const PASSWORD_EXPIRES_FIELD = "passwordExpires";
export const USE_ROAD_FIELD = "useRoadNotAir";
export const USE_6_INCH_LABEL_FIELD = "use6InchLabel";
export const INCLUDE_REFERENCE_CUSTOMER_ZONE = "includeRefCustomerZone";
export const INCLUDE_REFERENCE_AS_BARCODE = "referenceAsBarcode";
export const INCLUDE_TRACKING_BARCODE_FIELD = "includeTrackingBarcode";
export const INCLUDE_CUSTOMER_CODE_FIELD = "includeCustomerCode";
export const HOMECALL_REFERENCE = "homecallReference";
export const CHANGE_MESSAGE_FIELD = "changeMessage";
export const DEFAULT_PASSWORD = "PASSWORD";
export const NEW_PASSWORD_FIELD = "newPassword";
export const RE_ENTER_PASSWORD_FIELD = "reEnterPassword";
export const ADMIN_ACCESS_FIELD = "isAdminAccess";
export const CREATE_PERMISSION_FIELD = "isDpdAdmin";
export const PERMISSION_TO_CREATE_MASTER_USERS = "isCreateMasterUser";

export const TITLE_OPTIONS = {
  MR: {
    label: "Mr",
    value: "mr",
  },
  MRS: {
    label: "Mrs",
    value: "mrs",
  },
  MISS: {
    label: "Miss",
    value: "miss",
  },
  MS: {
    label: "Ms",
    value: "ms",
  },
};
export const TITLE_VALUES = [
  TITLE_OPTIONS.MR.value,
  TITLE_OPTIONS.MISS.value,
  TITLE_OPTIONS.MRS.value,
  TITLE_OPTIONS.MS.value,
];

export const LABEL_SETTINGS = [
  USE_6_INCH_LABEL_FIELD,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_TRACKING_BARCODE_FIELD,
  INCLUDE_CUSTOMER_CODE_FIELD,
  HOMECALL_REFERENCE,
];
