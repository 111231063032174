import { difference, get, isArray } from "lodash";
import isEmpty from "lodash/isEmpty";
import { change, stopSubmit } from "redux-form";

import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import {
  EMAIL_ALREADY_IN_USE,
  RESET_PASSWORD_CONFIRM,
  SUSPEND,
  SUSPEND_THIS_USER,
  UNABLE_TO_CREATE_USER,
  UNABLE_TO_UPDATE_USER,
  UNSUSPEND,
  USER_ALREADY_CREATED,
  USER_ALREADY_EXIST,
  USER_CREATED_SUCCESSFULLY,
  USER_UPDATED_SUCCESSFULLY,
  USERNAME_ALREADY_EXIST,
} from "./user.constants";
import {
  getAnalyticIds,
  getDeleteUserAnalyticsIds,
  getFilteredUserUids,
  isUserAccountSuspended,
  prepareUserData,
} from "./user.model";
import {
  getAccountUsers,
  getCurrentUserEmail,
  getCurrentUserUsername,
  getCustomerAccountId,
  getUserBusinessId,
  getUserById,
} from "./user.selectors";
import * as userService from "./user.service";
import {
  ACCOUNT_VALIDATION_MODAL,
  RESET_PASSWORD_CONFIRMATION,
  UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL,
  UPDATE_USER_CONFIRMATION_MODAL,
  USER_UPDATE_CONFIRMED,
} from "../../constants/analytics";
import { DPD_SEARCH_FORM, USER_FORMS } from "../../constants/forms";
import {
  ARE_YOU_SURE_THESE_USERS,
  ARE_YOU_SURE_YOU_WANT_TO_UPDATE_USER,
  ARE_YOU_WANT_DELETE,
  CLOSE,
  CONFIRMATION_PASSWORD_RESET,
  INVALID_URL_LINK_MESSAGE,
  NO,
  SAVE,
  THESE_USERS,
  THIS_USER,
  YES,
} from "../../constants/strings";
import { CUSTOMER_USERS_PAGE, DPD, LOGIN_PAGE } from "../../router";
import {
  navigateTo,
  navigateToEditUser,
  navigateToHome,
} from "../../router/navigation";
import { isEmailValid } from "../../utils/commonValidation";
import * as localStorageUtils from "../../utils/localStorage";
import * as sentryUtils from "../../utils/sentry";
import { formatMessage } from "../../utils/string";
import { getEmailDomain } from "../app/app.modes";
import { USERNAME_IN_USE } from "../auth/auth.constants";
import { isUnableToAuthorise } from "../auth/auth.model";
import { createLoadingAction } from "../loader/loader.actions";
import {
  showInfoModal,
  showModal,
  showModalWithDelay,
} from "../modal/modal.actions";
import { ALIGN_BUTTONS, CUSTOMER_VALIDATION } from "../modal/modal.constants";
import { getCurrentAccountData } from "../myDpdUserForm/accountDetails/accountDetails.selectors";
import { USERNAME_FIELD } from "../myDpdUserForm/userDetails/userDetails.constants";
import { clearSearchField } from "../search/search.actions";
import { setTabsToInitialState } from "../tabs/tabs.actions";
import { mapMyDpdUserData } from "../userDetailsForm/accountDetails/accountDetails.models";
import { validateRequiredCustomerFields } from "../userDetailsForm/accountDetails/accountDetails.validate";
import { ALL_APP_ROLES } from "../userDetailsForm/systemAccess/systemAccess.constants";
import { AUTOGENERATED_USERNAME_FIELD } from "../userDetailsForm/userDetails/userDetails.constants";
import { fetchUserInfo } from "../userDetailsForm/userDetailsForm.actions";
import {
  VALIDATE_USER_CUSTOMER_DETAILS_FORMS,
  VALIDATE_USER_DETAILS_FORMS,
} from "../userDetailsForm/userDetailsForm.constants";
import { prepareUserDetailsData } from "../userDetailsForm/userDetailsForm.models";
import {
  getCustomerUserDetailsData,
  getSelectedAccount,
  getUserDetailsData,
  getUserInfo,
} from "../userDetailsForm/userDetailsForm.selector";

const ACTION_NAMESPACE = "USER";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CREATE_USER: createAsyncActionTypes("CREATE_USER"),
  CREATE_USER_WITH_DETAILS: createAsyncActionTypes("CREATE_USER_WITH_DETAILS"),
  UPDATE_USER: createAsyncActionTypes("UPDATE_USER"),
  DELETE_USER: createAsyncActionTypes("DELETE_USER"),
  SUSPEND_USER: createAsyncActionTypes("SUSPEND_USER"),
  DELETE_CREATE_USER: createAsyncActionTypes("DELETE_CREATE_USER"),
  MULTIPLE_USERS_UPDATE: createAsyncActionTypes("MULTIPLE_USERS_UPDATE"),
  MULTIPLE_CREATE_USERS_DELETE: createAsyncActionTypes(
    "MULTIPLE_CREATE_USERS_DELETE"
  ),
  SET_USER_SUSPENDED: createAsyncActionTypes("SET_USER_SUSPENDED"),
  SET_USER_DELETED: createAsyncActionTypes("SET_USER_DELETED"),
  FETCH_ACCOUNT_USERS: createAsyncActionTypes("FETCH_ACCOUNT_USERS"),
  FETCH_USER: createAsyncActionTypes("FETCH_USER"),
  FETCH_USERNAMES: createAsyncActionTypes("FETCH_USERNAMES"),
  UPDATE_LOCAL_USER: "UPDATE_LOCAL_USER",
  DELETE_LOCAL_USER: "DELETE_LOCAL_USER",
  MULTIPLE_LOCAL_USERS_UPDATE: "MULTIPLE_LOCAL_USERS_UPDATE",
  UPDATE_CUSTOMER: createAsyncActionTypes("UPDATE_CUSTOMER"),
  CLEAR: "CLEAR",
  CLEAR_USERNAMES: "CLEAR_USERNAMES",
});

export const updateLocalUser = (uid, values) =>
  createPayloadAction(ACTIONS.UPDATE_LOCAL_USER, { uid, values });

export const deleteLocalUser = (uid) =>
  createPayloadAction(ACTIONS.DELETE_LOCAL_USER, uid);

export const updateMultipleLocalUsers = (uids, values) =>
  createPayloadAction(ACTIONS.MULTIPLE_LOCAL_USERS_UPDATE, { uids, values });

export const clearUser = () => createAction(ACTIONS.CLEAR);

export const clearUsernames = () => createAction(ACTIONS.CLEAR_USERNAMES);

export const onChangeGeneratedUserName = () =>
  change(USER_FORMS.DPD_USER_DETAILS, AUTOGENERATED_USERNAME_FIELD, false);

export const resetUsername = () =>
  change(USER_FORMS.DPD_USER_DETAILS, USERNAME_FIELD, "");

export const fetchInvitedUsers = createLoadingAction(
  createAsyncAction(
    (accountNumber) => () => userService.fetchAccountUsers(accountNumber, true),
    ACTIONS.FETCH_ACCOUNT_USERS
  )
);

export const clearUserData = () => (dispatch) => {
  localStorageUtils.clear();
  sentryUtils.setSentryUser(null);
  dispatch(clearUser());
};

export const fetchAccountUsers = createLoadingAction(
  createAsyncAction(
    (accountNumber) => userService.fetchAccountUsers(accountNumber),
    ACTIONS.FETCH_ACCOUNT_USERS
  )
);

export const fetchCurrentUser = createAsyncAction(
  userService.fetchCurrentUser,
  ACTIONS.FETCH_USER
);

export const fetchUsernames = createAsyncAction(
  userService.getGeneratedUsername,
  ACTIONS.FETCH_USERNAMES
);

export const deleteTempUser = createLoadingAction(
  createAsyncAction(
    (uid) => (dispatch) =>
      userService.deleteTempUser(uid).then(() => {
        dispatch(deleteLocalUser(uid));
      }),
    ACTIONS.DELETE_CREATE_USER
  )
);

export const suspendUser = createLoadingAction(
  createAsyncAction(
    (uid, values) => (dispatch) =>
      userService.suspendUser(uid, values).then(() => {
        dispatch(updateLocalUser(uid, values));
      }),
    ACTIONS.SUSPEND_USER
  )
);

export const updateUser = createLoadingAction(
  createAsyncAction(
    (uid, values) => (dispatch) =>
      userService.customerUserUpdate(uid, values).then(() => {
        dispatch(updateLocalUser(uid, values));
      }),
    ACTIONS.UPDATE_USER
  )
);

export const deleteUser = createLoadingAction(
  createAsyncAction(
    (uid, values) => (dispatch, getState) =>
      userService.deleteUser(uid, values).then(() => {
        const users = getAccountUsers(getState());
        const filteredUsers = users.filter((user) => user.uid !== uid);
        if (!filteredUsers.length) {
          dispatch(clearSearchField());
        }
        dispatch(change(DPD_SEARCH_FORM, "searchResults", filteredUsers));
        dispatch(deleteLocalUser(uid));
      }),
    ACTIONS.DELETE_USER
  )
);

export const createUser = createLoadingAction(
  createAsyncAction(userService.createUser, ACTIONS.CREATE_USER)
);

export const onToggleResendInviteClick = createLoadingAction(
  createAsyncAction(
    (uid) => (dispatch) => {
      const updates = [];
      if (isArray(uid)) {
        uid.forEach((userId) => {
          updates.push(
            userService
              .resendEmail(userId)
              .then(() => {
                dispatch(
                  updateLocalUser(userId, { isTemp: true, resendInvite: true })
                );
              })
              .catch((error) => {
                if (isUnableToAuthorise(error)) {
                  return { error };
                }
                dispatch(
                  updateLocalUser(userId, {
                    isTemp: false,
                    resendInvite: false,
                  })
                );
                dispatch(showInfoModal(USER_ALREADY_CREATED));
              })
          );
        });
      } else {
        userService
          .resendEmail(uid)
          .then(() => {
            dispatch(
              updateLocalUser(uid, { isTemp: true, resendInvite: true })
            );
          })
          .catch((error) => {
            if (isUnableToAuthorise(error)) {
              return { error };
            }
            dispatch(
              updateLocalUser(uid, { isTemp: false, resendInvite: false })
            );
            dispatch(showInfoModal(USER_ALREADY_CREATED));
          });
      }
      return Promise.all(updates);
    },
    ACTIONS.UPDATE_USER
  )
);

export const showSuspendUserModal =
  (updateUser) => (uid, accountSuspended) => (dispatch) => {
    const message = accountSuspended ? UNSUSPEND : SUSPEND;
    const contentText = formatMessage(SUSPEND_THIS_USER, message);

    dispatch(
      showModal({
        ...getAnalyticIds([uid], accountSuspended),
        contentText,
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () =>
          dispatch(updateUser(uid, { accountSuspended: !accountSuspended })),
      })
    );
  };

export const onToggleSuspendUnsuspendUser = showSuspendUserModal(suspendUser);

export const showDeleteUserModal =
  (updateUser) => (uid, user) => (dispatch) => {
    dispatch(
      showModal({
        ...getDeleteUserAnalyticsIds([uid]),
        contentText: "Are you sure you want to delete this user?",
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () => {
          dispatch(updateUser(uid, user));
        },
      })
    );
  };

export const deleteSingleUser = (uid, user) => (dispatch) => {
  if (user.isTemp) {
    dispatch(deleteTempUser(uid));
  } else {
    dispatch(deleteUser(uid, { accountDeleted: true }));
  }
};

export const onUserDeletePress = showDeleteUserModal(deleteSingleUser);

export const onUserPasswordResetPress = (id) => (dispatch) => {
  dispatch(
    showModal({
      contentText: RESET_PASSWORD_CONFIRM,
      cancelButtonText: NO,
      confirmButtonText: YES,
      interfaceId: RESET_PASSWORD_CONFIRMATION.INTERFACE_ID,
      loadId: RESET_PASSWORD_CONFIRMATION.LOAD,
      confirmActionId: RESET_PASSWORD_CONFIRMATION.YES,
      cancelActionId: RESET_PASSWORD_CONFIRMATION.NO,
      onConfirmClick: () => {
        userService.requestResetPassword(id).then(() => {
          dispatch(
            showModalWithDelay({
              confirmButtonText: CLOSE,
              showCancelButton: false,
              alignButton: ALIGN_BUTTONS.CENTER,
              contentText: CONFIRMATION_PASSWORD_RESET,
            })
          );
        });
      },
    })
  );
};

export const multipleUserUpdate = createLoadingAction(
  createAsyncAction(
    (uids, values) => (dispatch) =>
      userService.multipleUsersUpdate(uids, values).then(() => {
        dispatch(updateMultipleLocalUsers(uids, values));
      }),
    ACTIONS.MULTIPLE_USERS_UPDATE
  )
);

export const multipleTempUserDelete = createLoadingAction(
  createAsyncAction(
    (uids, values) => (dispatch) =>
      userService.multipleTempUsersDelete(uids).then(() => {
        dispatch(updateMultipleLocalUsers(uids, values));
      }),
    ACTIONS.MULTIPLE_CREATE_USERS_DELETE
  )
);

export const multipleSuspendUnsuspend =
  (uids, accountSuspended) => (dispatch, getState) => {
    const updateUids = [];
    uids.forEach((uid) => {
      const user = getUserById(uid)(getState());
      const currentAccountSuspended = isUserAccountSuspended(user);
      if (currentAccountSuspended === accountSuspended) {
        updateUids.push(uid);
      }
    });
    dispatch(
      showModal({
        ...getAnalyticIds(updateUids, accountSuspended),
        contentText: formatMessage(
          ARE_YOU_SURE_THESE_USERS,
          accountSuspended ? UNSUSPEND : SUSPEND,
          updateUids.length === 1 ? THIS_USER : THESE_USERS
        ),
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () => {
          dispatch(
            multipleUserUpdate(updateUids, {
              accountSuspended: !accountSuspended,
            })
          );
        },
      })
    );
  };

export const multipleDeletePress = (uids) => (dispatch, getState) => {
  const users = getAccountUsers(getState());
  const createUserUidsForDelete = getFilteredUserUids(users, uids, true);
  const userUidsForDelete = getFilteredUserUids(users, uids);
  dispatch(
    showModal({
      ...getDeleteUserAnalyticsIds(uids),
      contentText: formatMessage(
        ARE_YOU_WANT_DELETE,
        uids.length === 1 ? THIS_USER : THESE_USERS
      ),
      cancelButtonText: NO,
      confirmButtonText: YES,
      onConfirmClick: () => {
        userUidsForDelete.length &&
          dispatch(
            multipleUserUpdate(userUidsForDelete, { accountDeleted: true })
          );
        createUserUidsForDelete.length &&
          dispatch(
            multipleTempUserDelete(createUserUidsForDelete, {
              accountDeleted: true,
            })
          );
      },
    })
  );
};

export const onCreateDpdUser = (formValues) => async (dispatch) => {
  const userData = prepareUserData(formValues);

  await dispatch(createUser(userData));

  navigateTo(DPD);
};

export const onConfirmCreateUser = (id, customerId) => (dispatch) => {
  if (id) {
    return dispatch(
      showModal({
        interfaceId: UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL.INTERFACE_ID,
        loadId: UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL.LOAD,
        confirmActionId: UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL.YES,
        cancelActionId: UPDATE_CUSTOMER_USER_CONFIRMATION_MODAL.NO,
        contentText: ARE_YOU_SURE_YOU_WANT_TO_UPDATE_USER,
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () => dispatch(onCreateOrUpdateDpdCustomerUser(id)),
      })
    );
  } else {
    return dispatch(onCreateOrUpdateDpdCustomerUser(id, customerId));
  }
};

export const onConfirmCreateDPDUser = (id, customerId) => (dispatch) => {
  if (id) {
    return dispatch(
      showModal({
        interfaceId: UPDATE_USER_CONFIRMATION_MODAL.INTERFACE_ID,
        loadId: UPDATE_USER_CONFIRMATION_MODAL.LOAD,
        confirmActionId: UPDATE_USER_CONFIRMATION_MODAL.YES,
        cancelActionId: UPDATE_USER_CONFIRMATION_MODAL.CANCEL,
        contentText: ARE_YOU_SURE_YOU_WANT_TO_UPDATE_USER,
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () =>
          dispatch(onCreateOrUpdateCustomerUser(id, customerId, true)),
      })
    );
  } else {
    return dispatch(onCreateOrUpdateCustomerUser(id, customerId, true));
  }
};

const onCreateOrUpdateUser = createLoadingAction(
  (uid, formsData, customerId = null) =>
    (dispatch, getState) => {
      const state = getState();
      const businessId = getUserBusinessId(state);
      const account = customerId || getSelectedAccount(state);

      const userData = prepareUserDetailsData(formsData, {
        businessId,
        account,
      });
      if (uid) {
        const user = getUserInfo(state);
        const customAppRoles = difference(user.appRoles, ALL_APP_ROLES);

        return userService.customerUserUpdate(uid, {
          ...userData,
          appRoles: [...customAppRoles, ...userData.appRoles],
        });
      }

      let adminName = getCurrentUserUsername(state);
      if (adminName && !isEmailValid(adminName)) {
        adminName = `${adminName}${getEmailDomain()}`;
      } else {
        adminName = getCurrentUserEmail(state);
      }

      return userService.createUser({
        ...userData,
        adminName,
      });
    }
);

const createUserWithDetails = createAsyncAction(
  (uid, formsData, customerId) => async (dispatch) => {
    const analyticIds = uid
      ? {
          interfaceId: USER_UPDATE_CONFIRMED.INTERFACE_ID,
          loadId: USER_UPDATE_CONFIRMED.LOAD,
          confirmActionId: USER_UPDATE_CONFIRMED.CLOSE,
        }
      : {};
    try {
      const user = await dispatch(
        onCreateOrUpdateUser(uid, formsData.values, customerId)
      );

      dispatch(
        showInfoModal(
          uid ? USER_UPDATED_SUCCESSFULLY : USER_CREATED_SUCCESSFULLY,
          {
            ...analyticIds,
          }
        )
      );

      if (!uid && isEmailValid(user.username)) {
        navigateToHome(customerId);
      } else if (uid) {
        dispatch(fetchUserInfo(user.uid, customerId));
      } else if (!uid) {
        dispatch(fetchUserInfo(user.uid, customerId));
        navigateToEditUser(customerId, user.uid);
      }

      return user;
    } catch (e) {
      if (isUnableToAuthorise(e)) {
        return { error: e };
      }

      if (
        `${USERNAME_ALREADY_EXIST} ${USER_ALREADY_EXIST}`.includes(e.message)
      ) {
        let errors = {
          username: USERNAME_IN_USE,
        };
        if (
          formsData.values[USER_FORMS.DPD_USER_DETAILS].email ===
          formsData.values[USER_FORMS.DPD_USER_DETAILS].username
        ) {
          errors = {
            email: EMAIL_ALREADY_IN_USE,
          };
        }
        dispatch(stopSubmit(USER_FORMS.DPD_USER_DETAILS, errors));
      } else {
        if (e.statusCode === 401) {
          return navigateTo(LOGIN_PAGE);
        }
        dispatch(
          showInfoModal(
            uid ? UNABLE_TO_UPDATE_USER : UNABLE_TO_CREATE_USER,
            analyticIds
          )
        );
      }
    }
  },
  ACTIONS.CREATE_USER_WITH_DETAILS
);

const createOrUpdateUserWithDetails =
  (form, formDataSelector) =>
  (uid = null, customerId = null, checkCustomerValidMyDpdUser) =>
  async (dispatch, getState) => {
    const formsData = formDataSelector(getState());
    const user = getUserInfo(getState());

    if (
      checkCustomerValidMyDpdUser &&
      !get(user, "customerAddress.postcode", null)
    ) {
      const customer = getCurrentAccountData(customerId)(getState());
      const customerData = mapMyDpdUserData(customer);
      const customerValidationErrors =
        validateRequiredCustomerFields(customerData);

      if (!isEmpty(customerValidationErrors)) {
        return dispatch(
          showModal({
            interfaceId: ACCOUNT_VALIDATION_MODAL.INTERFACE_ID,
            loadId: ACCOUNT_VALIDATION_MODAL.ON_LOAD,
            confirmActionId: ACCOUNT_VALIDATION_MODAL.CLICK_SAVE,
            cancelActionId: ACCOUNT_VALIDATION_MODAL.CLICK_CANCEL,
            initialValues: customerData,
            customComponent: CUSTOMER_VALIDATION,
            confirmButtonText: SAVE,
            onConfirmClick: () => {
              const formsData = formDataSelector(getState());
              dispatch(createUserWithDetails(uid, formsData, customerId));
            },
          })
        );
      }
    }

    dispatch(createUserWithDetails(uid, formsData, customerId));
  };

export const onCreateOrUpdateDpdCustomerUser = createOrUpdateUserWithDetails(
  VALIDATE_USER_DETAILS_FORMS,
  getUserDetailsData
);

export const onCreateOrUpdateCustomerUser = createOrUpdateUserWithDetails(
  VALIDATE_USER_CUSTOMER_DETAILS_FORMS,
  getCustomerUserDetailsData
);

export const prepareRequiredEditData = createLoadingAction(
  (id) => async (dispatch) => {
    await dispatch(fetchUserInfo(id));
    dispatch(setTabsToInitialState());
  }
);

export const showInvalidUrlModal = () => (dispatch, getState) => {
  const customerId = getCustomerAccountId(getState());

  dispatch(
    showModal({
      contentText: INVALID_URL_LINK_MESSAGE,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
      onConfirmClick: () => navigateTo(CUSTOMER_USERS_PAGE, { customerId }),
    })
  );
};

export const onUserDeleteWithModal = showDeleteUserModal(deleteSingleUser);

export const onDeleteUserClick = (uid, user) => (dispatch) =>
  dispatch(onUserDeleteWithModal(uid, user));
