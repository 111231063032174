import { get } from "lodash";
import { getFormValues, hasSubmitSucceeded } from "redux-form";
import { createSelector } from "reselect";

import { CUSTOMER_MIGRATION_SEARCH_FORM } from "../../constants/forms";
import { SEARCH_PARAM_FIELD, SEARCH_VALUE_FIELD } from "./search.constants";

export const getMigrationSearchFormValues = getFormValues(
  CUSTOMER_MIGRATION_SEARCH_FORM
);

export const getSearchMigrationResults = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, "searchResults", {})
);

export const getSearchValue = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_VALUE_FIELD, "")
);

export const getSearchParam = createSelector(
  getMigrationSearchFormValues,
  (state) => get(state, SEARCH_PARAM_FIELD, "")
);

export const isFormSubmitted = (state) =>
  hasSubmitSucceeded(CUSTOMER_MIGRATION_SEARCH_FORM)(state);
