export const SEARCH_PARAM_FIELD = "searchParam";
export const SEARCH_VALUE_FIELD = "searchValue";
export const VERSION_FIELD = "version";
export const SEARCH_RESULTS_FIELD = "searchResults";

export const SEARCH_PARAMS = {
  ACCOUNT: "account",
  ACCOUNTS: "accounts",
  COMPANY_NAME: "companyName",
};

export const SEARCH_USER_TABLE_HEADERS = [
  "LOGON ID",
  "ACCOUNT",
  "BUSINESS UNIT",
  "ACTUAL APP VERSION",
  "EXPECTED APP VERSION",
];

export const SEARCH_CUSTOMER_TABLE_HEADERS = [
  "ACCOUNT ID",
  "BUSINESS UNIT",
  "NAME",
];

export const CUSTOMER_COLUMS = [
  {
    width: 250,
    label: "ACCOUNT ID",
    dataKey: "account",
  },
  {
    width: 250,
    label: "BUSINESS UNIT",
    dataKey: "businessUnit",
  },
  {
    width: 250,
    label: "NAME",
    dataKey: "customername",
  },
];

export const USER_COLUMS = [
  {
    width: 240,
    label: "LOGON ID",
    dataKey: "username",
  },
  {
    width: 125,
    label: "ACCOUNT",
    dataKey: "account",
  },
  {
    width: 140,
    label: "BUSINESS UNIT",
    dataKey: "businessUnit",
  },
  {
    width: 150,
    label: "ACTUAL APP VERSION",
    dataKey: "dpdLocalAppVersionActual",
  },
  {
    width: 150,
    label: "EXPECTED APP VERSION",
    dataKey: "dpdLocalAppVersionExpected",
  },
];
