import { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose, withHandlers, withState } from "recompose";
import { Field, reduxForm } from "redux-form";
import { isEmpty } from "lodash";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as CheckboxIcon } from "../../../../assets/icons/checkbox.svg";
import DpdIconLocal from "../../../../assets/icons/dpd-local.svg";
import DpdIcon from "../../../../assets/icons/dpd.svg";
import { ReactComponent as Suspend } from "../../../../assets/icons/suspend.svg";
import { ReactComponent as CheckBoxWithTick } from "../../../../assets/icons/ticked-box.svg";
import { ReactComponent as User } from "../../../../assets/icons/username.svg";
import HashIcon from "../../../../components/HashIcon/HashIcon";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import SimpleTable from "../../../../components/Table/Table";
import { HOME_PAGE_ANALYTICS } from "../../../../constants/analytics";
import { DPD_SEARCH_FORM } from "../../../../constants/forms";
import {
  ACCOUNT_NUMBER,
  BACK_TO_RESULT,
  BUSINESS_ID,
  DPD,
  DPD_LOCAL,
  LOGON_ID,
  NO_USERS_TO_SHOW,
  SEARCH,
  SEARCH_TABLE_HEADERS,
  SUSPEND_DELETE_USER_COLUMN,
  VIEW_INVITED_USERS,
} from "../../../../constants/strings";
import {
  clearSearchField,
  onSearchUsersClick,
  onSuspendUnsuspendUserClick,
} from "../../../../features/search/search.actions";
import {
  ACCOUNT_FIELD,
  BUSINESS_ID_FIELD,
  SEARCH_RESULT,
  USERNAME_FIELD,
} from "../../../../features/search/search.constants";
import { getInitialSearchFormValues } from "../../../../features/search/search.models";
import {
  getIsExistInvitedUsersField,
  getIsTempUsersField,
  getSearchFormValues,
  getSearchResults,
  getSubmissionErrors,
  isSubmittingForm,
} from "../../../../features/search/search.selectors";
import {
  onDeleteUserClick,
  onToggleResendInviteClick,
} from "../../../../features/user/user.actions";
import { getAccountUsers } from "../../../../features/user/user.selectors";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import { DPD_UPDATE_FOUND_USER } from "../../../../router";
import { getPageLink } from "../../../../router/navigation";
import InvitedUsersTools from "../../../main/components/tableComponents/InvitedUsersTools";
import Tools from "../../../main/components/tableComponents/Tools";
import styles from "./SearchForm.module.scss";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const SearchForm = ({
  handleSubmit,
  onSearchClick,
  onBusinessIdClick,
  onClearField,
  searchResults,
  disabledField,
  onLogonIdClick,
  onDeleteClick,
  onSuspendUnsuspendClick,
  onDisableFields,
  onViewInvitedClick,
  onEnterClick,
  isTempUsers,
  onHover,
  onToggleResendInvite,
  selectedRows,
  onChangeCheckbox,
  users,
  isSubmitting,
  submissionErrors,
}) => {
  const renderItem = (field, columnIndex, id, data) => {
    const disabled = data.accountSuspended || data.accountType;
    const user = users.find((user) => user.id === id);
    let content = field;

    if (columnIndex === 0) {
      content = (
        <Grid container>
          {isTempUsers && (
            <Grid item>
              <Checkbox
                id={id}
                onChange={onChangeCheckbox}
                checked={!!selectedRows.find((selected) => selected === id)}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckBoxWithTick />}
                className={classNames(styles["all-checkbox"])}
                indeterminateIcon={<CheckboxIcon />}
              />
            </Grid>
          )}
          {!data.accountType && !isTempUsers ? (
            <>
              {data.accountSuspended && <Suspend />}
              <Grid item className={classNames(styles.link)}>
                <Link
                  to={getPageLink(DPD_UPDATE_FOUND_USER, { id })}
                  onClick={onLogonIdClick}
                >
                  {field}
                </Link>
              </Grid>
            </>
          ) : (
            <Grid item className={classNames(styles.text)}>
              {field}
            </Grid>
          )}
        </Grid>
      );
    }

    const ToolsComponent = isTempUsers ? InvitedUsersTools : Tools;

    if (field === SUSPEND_DELETE_USER_COLUMN) {
      return (
        <div className={styles.tools}>
          <ToolsComponent
            showEditButton={false}
            showSuspendButton={!data.accountType}
            onDeletePress={onDeleteClick}
            onSuspendPress={onSuspendUnsuspendClick}
            accountSuspended={data.accountSuspended}
            showResendInviteButton={true}
            resendInvite={user ? user.resendInvite : false}
            onResendInvite={onToggleResendInvite}
            user={data}
            id={data.id}
          />
        </div>
      );
    }

    return <div className={disabled && styles.disabled}>{content}</div>;
  };
  useEffect(() => {
    if (
      !searchResults.length &&
      disabledField &&
      !isSubmitting &&
      isEmpty(submissionErrors)
    ) {
      onClearField();
    }
  }, [disabledField, searchResults, isSubmitting, submissionErrors]);

  return (
    <Grid className={styles.container}>
      <Grid item>
        <Field
          component={SelectWithTitle}
          label={BUSINESS_ID}
          name={BUSINESS_ID_FIELD}
          onChange={() => {
            onBusinessIdClick();
            onClearField();
          }}
        >
          <MenuItem value={DPD_BUSINESS_ID}>
            <SelectItem icon={DpdIcon} title={DPD} />
          </MenuItem>
          <MenuItem value={DPD_LOCAL_BUSINESS_ID}>
            <SelectItem icon={DpdIconLocal} title={DPD_LOCAL} />
          </MenuItem>
        </Field>
      </Grid>
      <Grid container className={styles.inputContainer}>
        <Grid item className={styles.input}>
          <Field
            name={USERNAME_FIELD}
            component={DecoratedFieldWithError}
            label={LOGON_ID}
            icon={<User />}
            onClearClick={() => onClearField(USERNAME_FIELD)}
            onKeyDown={(e) => e.keyCode === 13 && onEnterClick()}
            disabled={disabledField === USERNAME_FIELD}
          />
        </Grid>
        <Grid item className={styles.input}>
          <Field
            name={ACCOUNT_FIELD}
            component={DecoratedFieldWithError}
            label={ACCOUNT_NUMBER}
            icon={<HashIcon />}
            onClearClick={() => onClearField(ACCOUNT_FIELD)}
            disabled={disabledField === ACCOUNT_FIELD}
            onKeyDown={(e) => e.keyCode === 13 && onEnterClick()}
          />
        </Grid>
        {disabledField === ACCOUNT_FIELD ? (
          <Grid className={styles.buttonContainer}>
            <Button
              color="primary"
              onClick={() => {
                onDisableFields();
                handleSubmit(onViewInvitedClick)();
              }}
            >
              {isTempUsers ? BACK_TO_RESULT : VIEW_INVITED_USERS}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item container className={styles.content}>
        {searchResults.length ? (
          <Field
            name={SEARCH_RESULT}
            component={SimpleTable}
            props={{ renderItem }}
            headerRows={SEARCH_TABLE_HEADERS}
            selectedStyles={classNames(isTempUsers && styles["row-selected"])}
            selectedRows={selectedRows}
            rowStyles={classNames(
              styles.rowStyles,
              !isTempUsers && styles["row-hover"]
            )}
            onRowHover={onHover}
          />
        ) : null}
        {!disabledField ? (
          <Button
            onClick={() => {
              onDisableFields();
              handleSubmit(onSearchClick)();
            }}
          >
            <span className={styles.buttonText}>{SEARCH}</span>
          </Button>
        ) : null}
      </Grid>
      {disabledField === ACCOUNT_FIELD &&
      !searchResults.length &&
      isTempUsers ? (
        <div className={styles.warningContainer}>
          <Typography className={styles.warning}>{NO_USERS_TO_SHOW}</Typography>
        </div>
      ) : null}
    </Grid>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  isTempUsers: PropTypes.bool,
  isExistInvitedUsers: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  users: PropTypes.array,
  searchResults: PropTypes.array,
  onSearchClick: PropTypes.func,
  onDisableFields: PropTypes.func,
  onBusinessIdClick: PropTypes.func,
  onEnterClick: PropTypes.func,
  onClearField: PropTypes.func,
  disabledField: PropTypes.string,
  onLogonIdClick: PropTypes.func,
  setDisabled: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onViewInvitedClick: PropTypes.func,
  onSuspendUnsuspendClick: PropTypes.func,
  onToggleResendInvite: PropTypes.func,
  onHover: PropTypes.func,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  submissionErrors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  users: getAccountUsers(state),
  searchResults: getSearchResults(state),
  values: getSearchFormValues(state),
  isSubmitting: isSubmittingForm(state),
  isTempUsers: getIsTempUsersField(state),
  isExistInvitedUsers: getIsExistInvitedUsersField(state),
  submissionErrors: getSubmissionErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (formValues, withTempUsers) =>
    dispatch(onSearchUsersClick(formValues, withTempUsers)),
  onClear: () => dispatch(clearSearchField()),
  onDeleteClick: (id, user) => dispatch(onDeleteUserClick(id, user)),
  onSuspendUnsuspendClick: (id, accountSuspended, user) =>
    dispatch(onSuspendUnsuspendUserClick(id, accountSuspended, user)),
  onToggleResendInvite: (id) => dispatch(onToggleResendInviteClick(id)),
});

export default compose(
  reduxForm({
    form: DPD_SEARCH_FORM,
    initialValues: getInitialSearchFormValues(),
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState("disabledField", "setDisabled", ""),
  withState("selectedRows", "setSelectedRows", []),
  withHandlers({
    onDisableFields: (props) => () => {
      const { username, account } = props.values;
      if (!username && !account) {
        props.setDisabled("");
      } else {
        const populatedFieldName = username ? USERNAME_FIELD : ACCOUNT_FIELD;
        props.setDisabled(populatedFieldName);
      }
    },
    onSearchClick: (props) => (formValues) => props.onSearch(formValues, false),
    onViewInvitedClick: (props) => (formValues) => {
      const withTempUsers = !props.isTempUsers;
      return props.onSearch(formValues, withTempUsers);
    },
    onClearField: (props) => (fieldName) => {
      props.setDisabled("");
      props.setSelectedRows([]);
      props.onClear(fieldName);
    },
    onChangeCheckbox: (props) => (event) => {
      const id = event.target.id;
      if (event.target.checked) {
        props.setSelectedRows([...props.selectedRows, id]);
      } else {
        props.setSelectedRows(
          props.selectedRows.filter((selected) => selected !== id)
        );
      }
    },
  }),
  withTrackProps((props) => ({
    onLogonIdClick: HOME_PAGE_ANALYTICS.CLICK_LOGON_ID,
    onBusinessIdClick: HOME_PAGE_ANALYTICS.CLICK_DROPDOWN_LIST,
    onToggleResendInvite: HOME_PAGE_ANALYTICS.CLICK_RESEND_USER_INVITE,
    onChangeCheckbox: HOME_PAGE_ANALYTICS.INTERNAL_CLICK_CHECKBOX,
    onEnterClick: () => {
      props.onDisableFields();
      props.handleSubmit(props.onSearchClick)();
      return HOME_PAGE_ANALYTICS.CLICK_ENTER;
    },
    onDeleteClick: () => HOME_PAGE_ANALYTICS.INTERNAL_DELETE_USER,
    onSuspendUnsuspendClick: (userId, accountSuspended) =>
      !accountSuspended
        ? HOME_PAGE_ANALYTICS.INTERNAL_SUSPEND_USER
        : HOME_PAGE_ANALYTICS.INTERNAL_UNSUSPEND_USER,
    onSearchClick: HOME_PAGE_ANALYTICS.CLICK_SEARCH,
    onViewInvitedClick: HOME_PAGE_ANALYTICS.CLICK_VIEW_INVITED_USERS,
    onHover: () => HOME_PAGE_ANALYTICS.INTERNAL_HOVER,
    onClearField: (fieldName) =>
      fieldName === USERNAME_FIELD
        ? HOME_PAGE_ANALYTICS.CLICK_REMOVE_LOGON_ID
        : HOME_PAGE_ANALYTICS.CLICK_REMOVE_ACCOUNT_NUMBER,
  }))
)(SearchForm);
