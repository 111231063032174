import {
  getFormSubmitErrors,
  getFormValues,
  isDirty,
  isSubmitting,
} from "redux-form";
import { createSelector } from "reselect";

import { DPD_SEARCH_FORM } from "../../constants/forms";

export const getSearchFormValues = getFormValues(DPD_SEARCH_FORM);

export const getSearchFormBusinessId = createSelector(
  getSearchFormValues,
  ({ businessId }) => businessId
);

export const getSearchResults = createSelector(
  getSearchFormValues,
  ({ searchResults }) => searchResults || []
);

export const isSubmittingForm = createSelector(
  isDirty(DPD_SEARCH_FORM),
  isSubmitting(DPD_SEARCH_FORM),
  (isDirty, isSubmitting) => isDirty && isSubmitting
);

export const getIsTempUsersField = createSelector(getSearchFormValues, (data) =>
  data ? data.isTempUsers : false
);

export const getIsExistInvitedUsersField = createSelector(
  getSearchFormValues,
  ({ isExistInvitedUsers }) => isExistInvitedUsers || false
);

export const getSubmissionErrors = getFormSubmitErrors(DPD_SEARCH_FORM);
