import { filter, isArray, union } from "lodash";
import get from "lodash/get";
import { change, clearSubmitErrors, initialize } from "redux-form";

import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { getAccountDetailsInitialValues } from "./accountDetails/accountDetails.models";
import {
  getPostcode,
  getSelectedAccountDetails,
} from "./accountDetails/accountDetails.selectors";
import { enableMiscellaneousEodFields } from "./miscellaneousConfiguration/miscellaneousConfiguration.actions";
import { getShippingInitialValues } from "./shippingSettings/shippingSettings.models";
import { clearPasswordSection } from "./userDetails/userDetails.actions";
import { USERNAME_FIELD } from "./userDetails/userDetails.constants";
import { getUserDetailsInitialValues } from "./userDetails/userDetails.models";
import { getEmail, getUserId } from "./userDetails/userDetails.selectors";
import { updateFormMapper } from "./userDetailsForm.models";
import {
  prepareProfilesForm,
  registerAllocatedProfileField,
} from "./userProfiles/userProfiles.actions";
import { CHANGE_MESSAGE_MODAL } from "../../constants/analytics";
import { USER_DETAILS_FORMS, USER_FORMS } from "../../constants/forms";
import {
  CHANGE_MESSAGE,
  CLOSE,
  NO_USER_WITH_CURRENT_ID,
  WARNING,
} from "../../constants/strings";
import { ROOT } from "../../router";
import { navigateTo } from "../../router/navigation";
import { getConfig } from "../config/config.selectors";
import {
  fetchCustomerAccountsByIds,
  fetchPermittedIp,
} from "../customer/customer.actions";
import { hasMasterEodUser } from "../customer/customer.selectors";
import * as customerService from "../customer/customer.service";
import { createLoadingAction } from "../loader/loader.actions";
import { showModal } from "../modal/modal.actions";
import { ALIGN_BUTTONS, WIDTH_TYPE } from "../modal/modal.constants";
import { getProfilesByAccount } from "../profile/profile.service";
import { setTabsToInitialState } from "../tabs/tabs.actions";
import { onChangeGeneratedUserName } from "../user/user.actions";
import { isCustomerAdmin } from "../user/user.model";
import * as userService from "../user/user.service";

const ACTION_NAMESPACE = "USER_FORM";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CHANGE_ACCOUNT: "CHANGE_ACCOUNT",
  FETCH_USER_INFO: createAsyncActionTypes("FETCH_USER_INFO"),
  PREPARE_CUSTOMER_DATA: createAsyncActionTypes("PREPARE_CUSTOMER_DATA"),
  CLEAR: "CLEAR",
});

const initializeForms = (props, isCustomerUser) => (dispatch, getState) => {
  const state = getState();
  const config = getConfig(state);

  const formData = {
    ...props,
    ...config,
    customer: props.customer,
  };

  const hasAdminAccess = isCustomerAdmin(formData.userInfo);
  Object.entries(updateFormMapper(isCustomerUser)).map(([form, mapper]) => {
    if (form === USER_FORMS.SYSTEM_ACCESS_ACCOUNTS && !hasAdminAccess) {
      // eslint-disable-next-line array-callback-return
      return;
    }

    return dispatch(initialize(form, mapper(formData)));
  });
};

export const redirectNotFoundUser = () => (dispatch) => {
  navigateTo(ROOT);

  return dispatch(
    showModal({
      contentText: NO_USER_WITH_CURRENT_ID,
      notificationType: WARNING,
      title: WARNING,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
    })
  );
};

export const showChangeMessageModal = (changeMessage) => (dispatch) => {
  if (changeMessage) {
    dispatch(
      showModal({
        confirmButtonText: CLOSE,
        customComponent: CHANGE_MESSAGE,
        showCancelButton: false,
        alignButton: ALIGN_BUTTONS.RIGHT,
        widthType: WIDTH_TYPE.MEDIUM,
        title: CHANGE_MESSAGE,
        changeMessage,
        loadId: CHANGE_MESSAGE_MODAL.LOAD,
        interfaceId: CHANGE_MESSAGE_MODAL.INTERFACE_ID,
        confirmActionId: CHANGE_MESSAGE_MODAL.CLOSE,
      })
    );
  }
};

export const getAccountsModels = async (userInfo) => {
  const userAccountIds = userInfo.accounts || [];
  const adminAccountIds = userInfo.customerAdminAccounts || [];
  let accounts = await customerService.getCustomerByAccountNumber(
    union(userAccountIds, adminAccountIds),
    userInfo.businessId
  );
  if (!isArray(accounts)) {
    accounts = [accounts];
  }
  const additionalAccounts = filter(accounts, (item) =>
    userAccountIds.includes(get(item, "account"))
  );
  const customerAdminAccounts = filter(accounts, (item) =>
    adminAccountIds.includes(get(item, "account"))
  );
  return { additionalAccounts, customerAdminAccounts };
};

export const fetchUserInfo = createAsyncAction(
  (uid = "", customerId) =>
    async (dispatch) => {
      const user = await userService.fetchUser(uid);
      if (!user) {
        dispatch(redirectNotFoundUser());
      }

      const userInfo = { ...user, uid };
      const customer = await customerService.getCustomerByAccountNumber(
        customerId || userInfo.account,
        user.businessId
      );
      const permittedIp = await customerService.getCustomerIpAddress(
        customerId || userInfo.account,
        user.businessId
      );

      const accounts = await getAccountsModels(userInfo);
      const { additionalAccounts, customerAdminAccounts } = accounts;

      if (!customerId) {
        dispatch(showChangeMessageModal(userInfo.changeMessage));
      }

      const profiles = await getProfilesByAccount(
        customerId || userInfo.account
      );
      dispatch(
        initializeForms(
          {
            userInfo,
            customer: { ...customer, permittedIp },
            additionalAccounts,
            profiles,
            customerAdminAccounts,
          },
          !!customerId
        )
      );
      dispatch(fetchPermittedIp());
      return { customer: { ...customer, permittedIp }, user: userInfo };
    },
  ACTIONS.FETCH_USER_INFO
);

export const onAccountChange = (payload) =>
  createPayloadAction(ACTIONS.CHANGE_ACCOUNT, payload);

const changeUserDetailsField = (field, value) =>
  change(USER_DETAILS_FORMS.DPD_USER_DETAILS, field, value);

export const changeUsername = (username) => (dispatch) =>
  dispatch(changeUserDetailsField(USERNAME_FIELD, username));

export const onAdminAccessToggle = (value) => (dispatch, getState) => {
  if (value) {
    const state = getState();
    const selectedAccount = getSelectedAccountDetails(state);
    const postcode = getPostcode(state);
    const id = getUserId(state);

    if (!id) {
      const email = getEmail(state);

      dispatch(changeUsername(email));
    }

    dispatch(clearPasswordSection());
    dispatch(onChangeGeneratedUserName());
    selectedAccount.account &&
      postcode &&
      dispatch(setAccountToAdminAccess(selectedAccount));
  }

  !value && dispatch(clearSubmitErrors(USER_DETAILS_FORMS.DPD_USER_DETAILS));
};

export const setAccountToAdminAccess = (account) => (dispatch) => {
  dispatch(
    change(
      USER_DETAILS_FORMS.SYSTEM_ACCESS_ACCOUNTS,
      "accounts",
      account ? [account] : []
    )
  );
};

export const prepareRequiredCreateData = createLoadingAction(
  (customerId) => async (dispatch, getState) => {
    dispatch(registerAllocatedProfileField());
    await dispatch(prepareProfilesForm(customerId));
    await dispatch(prepareCustomerData(customerId));
    const hasMasterUser = hasMasterEodUser(customerId)(getState());
    hasMasterUser && dispatch(enableMiscellaneousEodFields());
    dispatch(setTabsToInitialState());
  }
);

export const prepareRequiredEditData = createLoadingAction(
  (id, customerId) => async (dispatch) => {
    dispatch(registerAllocatedProfileField());
    await dispatch(prepareCustomerData(customerId)); // NOTE: we should fetch customer before user
    // else it will cause reinitialize of miscellaneous configuration form and inconsistent data on ui
    await dispatch(fetchUserInfo(id, customerId));
    dispatch(setTabsToInitialState());
  }
);

export const prepareCustomerData = createAsyncAction(
  (selectedAccountId) => async (dispatch) => {
    const accounts = await dispatch(fetchCustomerAccountsByIds());
    await dispatch(fetchPermittedIp());
    return get(accounts, selectedAccountId, {});
  },
  ACTIONS.PREPARE_CUSTOMER_DATA
);

export const initializeCreateCustomerForm = () => (dispatch) => {
  dispatch(
    initialize(USER_FORMS.ACCOUNT_DETAILS, getAccountDetailsInitialValues())
  );
  dispatch(
    initialize(USER_FORMS.DPD_USER_DETAILS, getUserDetailsInitialValues())
  );
  dispatch(
    initialize(USER_FORMS.SHIPPING_SETTINGS, getShippingInitialValues())
  );
};

export const clearUserDetailsForm = () => createAction(ACTIONS.CLEAR);
