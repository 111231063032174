import { SUSPEND_DELETE_USER_COLUMN } from "../../constants/strings";
import { isDpdLocalDomain } from "../app/app.modes";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../userDetailsForm/accountDetails/accountDetails.constants";

export const createData = (uid, accountSuspended, accountType, ...fields) => {
  const tableFields = fields.reduce((acc, value, key) => {
    acc[`field${key}`] = value;
    return acc;
  }, {});
  return { id: uid, uid, accountSuspended, accountType, fields: tableFields };
};

export const prepareSearchTableData = (data) =>
  data.map(
    ({
      uid,
      accountSuspended,
      username,
      account,
      forename,
      lastname,
      accountType,
    }) =>
      createData(
        uid,
        accountSuspended,
        accountType,
        username,
        account,
        forename,
        lastname,
        SUSPEND_DELETE_USER_COLUMN
      )
  );

export const getInitialSearchFormValues = () => ({
  businessId: isDpdLocalDomain() ? DPD_LOCAL_BUSINESS_ID : DPD_BUSINESS_ID,
  searchResults: [],
  isExistInvitedUsers: false,
  username: "",
  account: "",
  isTempUsers: null,
});

export const updateSingleUser = (accountUsers, uid, values) =>
  accountUsers.map((item) => {
    if (item.id === uid) {
      return { ...item, ...values };
    }
    return item;
  });
