import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { convertToAssociativeArray } from "./customer.model";
import * as customerService from "./customer.service";
import { createLoadingAction } from "../loader/loader.actions";
import {
  getCustomerAccountId,
  getCustomerAdminAccounts,
  getUserBusinessId,
} from "../user/user.selectors";

const ACTION_NAMESPACE = "CUSTOMER";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  UPDATE_CUSTOMER: createAsyncActionTypes("UPDATE_CUSTOMER"),
  FETCH_CUSTOMER_PERMITTED_IP: createAsyncActionTypes(
    "FETCH_CUSTOMER_PERMITTED_IP"
  ),
  FETCH_USER_CUSTOMER_ACCOUNTS: createAsyncActionTypes(
    "FETCH_USER_CUSTOMER_ACCOUNTS"
  ),
});

export const updateCustomer = createLoadingAction(
  createAsyncAction(customerService.updateCustomer, ACTIONS.UPDATE_CUSTOMER)
);

export const fetchPermittedIp = createAsyncAction(
  () => async (dispatch, getState) => {
    const state = getState();
    const account = getCustomerAccountId(state);
    const businessId = getUserBusinessId(state);
    const permittedIp = await customerService.getCustomerIpAddress(
      account,
      businessId
    );
    return { account, permittedIp };
  },
  ACTIONS.FETCH_CUSTOMER_PERMITTED_IP
);

export const fetchCustomerAccountsByIds = createAsyncAction(
  () => async (dispatch, getState) => {
    const state = getState();
    const ids = getCustomerAdminAccounts(state);
    const businessId = getUserBusinessId(state);
    const customers = await customerService.getCustomerByAccountNumber(
      ids,
      businessId
    );
    return convertToAssociativeArray("account")(customers);
  },
  ACTIONS.FETCH_USER_CUSTOMER_ACCOUNTS
);
