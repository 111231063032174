import { Component } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./Table.module.scss";
import CustomHeader from "./tableComponents/CustomHeader";
import { EmptyTableComponent } from "./tableComponents/EmptyTableComponent";
import TablePaginationActions from "./tableComponents/TablePaginationActions";
import UserTableRow from "./tableComponents/TableRow";
import { ReactComponent as CheckboxIcon } from "../../../assets/icons/checkbox.svg";
import { ReactComponent as CheckBoxWithTick } from "../../../assets/icons/ticked-box.svg";
import { HOME_PAGE_ANALYTICS } from "../../../constants/analytics";
import {
  multipleDeletePress,
  multipleSuspendUnsuspend,
  onToggleResendInviteClick,
  onToggleSuspendUnsuspendUser,
  onUserDeletePress,
} from "../../../features/user/user.actions";
import {
  getAvailableForSelectUsers,
  isSuspendedAllUsers,
} from "../../../features/user/user.model";

class CustomizedTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: new Set([]),
      page: 0,
      rowsPerPage: 6,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users.length !== this.props.users.length) {
      if (this.state.selected.size) {
        this.removeOddSelectedItem();
      }

      if (!this.getCurrentPageUsers().length) {
        if (
          Math.abs(prevProps.users.length - this.props.users.length) >
          this.state.rowsPerPage
        ) {
          // todo remove this check when become save all filters(search, sortBy, sortOrder)
          this.changePage(0);
        } else {
          this.goToPreviousPage();
        }
      }
    }

    if (
      prevProps.activeFilter !== this.props.activeFilter ||
      prevProps.customerId !== this.props.customerId
    ) {
      this.changePage(0);
    }
  }

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  setSelected = (selected) => {
    this.setState({ selected });
  };

  setPage = (page) => {
    this.setState({ page });
  };

  removeOddSelectedItem = () => {
    const availableToSelectUsers = getAvailableForSelectUsers(
      this.getCurrentPageUsers()
    );
    const newSelectedSet = new Set(
      [...this.state.selected].filter((x) =>
        availableToSelectUsers.find(({ uid }) => uid === x)
      )
    );
    this.setSelected(newSelectedSet);
  };

  getCurrentPageUsers = () =>
    this.props.users.slice(this.getStartIndexUsers(), this.getEndIndexUsers());

  getStartIndexUsers = () => this.state.page * this.state.rowsPerPage;

  getEndIndexUsers = () =>
    this.state.page * this.state.rowsPerPage + this.state.rowsPerPage;

  goToPreviousPage = () => {
    const { page } = this.state;
    const previousPage = page ? page - 1 : 0;
    this.changePage(previousPage);
  };

  changePage = (pageNumber) => {
    this.setPage(pageNumber);
    this.setSelected(new Set([]));
  };

  isSelected = (id) => this.state.selected.has(id);

  onSelectAllClick = (checked) => {
    this.props.onSelectAll();
    if (checked) {
      const newSelected = new Set(
        getAvailableForSelectUsers(this.getCurrentPageUsers()).map((n) => n.uid)
      );
      return this.setSelected(newSelected);
    }
    this.setSelected(new Set([]));
  };

  onClick = (id) => {
    const newSelected = new Set(this.state.selected);
    if (this.isSelected(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    this.setSelected(newSelected);
    this.props.onClick();
  };

  onChangePage = (event, newPage) => {
    this.changePage(newPage);
  };

  onChangeRowsPerPage = (event) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.changePage(1);
  };

  render() {
    const {
      users,
      emptyTableComponent,
      ToolsComponent,
      currentUserId,
      showCreatedDate,
      onDelete,
      onMultipleUsersSuspend,
      onMultipleUsersDelete,
      onToggleSuspendUnsuspendUser,
      onToggleResendInvite,
      onEditUser,
      onHover,
    } = this.props;
    const { selected, page, rowsPerPage } = this.state;
    const availableUsers = getAvailableForSelectUsers(
      this.getCurrentPageUsers()
    );
    const currentUser = users.find((user) => user.uid === currentUserId);
    const showEmptyTableComponent = !users || users.length === 0;
    const usersExist = !!selected.size && !!availableUsers.length;
    const showTools = ToolsComponent && usersExist;
    return (
      <>
        <Grid container className={styles["tool-bar"]}>
          <Checkbox
            onChange={(event) => this.onSelectAllClick(event.target.checked)}
            indeterminate={
              !!(selected.size && selected.size < availableUsers.length)
            }
            icon={<CheckboxIcon />}
            checkedIcon={<CheckBoxWithTick />}
            className={classNames(
              styles["all-checkbox"],
              !availableUsers.length && styles["checkbox-hidden"]
            )}
            indeterminateIcon={<CheckboxIcon />}
            checked={selected.size === availableUsers.length}
          />
          {showTools && (
            <ToolsComponent
              id={Array.from(selected)}
              onSuspendPress={onMultipleUsersSuspend}
              onDeletePress={onMultipleUsersDelete}
              onEditUser={onEditUser}
              accountSuspended={!isSuspendedAllUsers(availableUsers, selected)}
              customerId={this.props.customerId}
              showResendInviteButton={false}
              showEditButton={selected.size === 1}
              {...this.props}
            />
          )}
        </Grid>

        <div className={styles.contentContainer}>
          <Table>
            <CustomHeader />

            <TableBody tabIndex={-1}>
              {showEmptyTableComponent ? (
                <EmptyTableComponent>{emptyTableComponent}</EmptyTableComponent>
              ) : (
                <>
                  {currentUser ? (
                    <UserTableRow
                      user={currentUser}
                      isItemSelected={this.isSelected(currentUserId)}
                      onDeletePress={onDelete}
                      currentUserId={currentUserId}
                      onEditUser={onEditUser}
                      handleClick={this.onClick}
                      showCreatedDate={showCreatedDate}
                      ToolsComponent={ToolsComponent}
                      onToggleSuspendUser={onToggleSuspendUnsuspendUser}
                      onToggleResendInvite={onToggleResendInvite}
                      customerId={this.props.customerId}
                      onRowHover={onHover}
                    />
                  ) : null}
                  {this.getCurrentPageUsers().map((user) => {
                    if (user.uid === currentUserId) {
                      return null;
                    }
                    return (
                      <UserTableRow
                        key={user.uid}
                        user={user}
                        isItemSelected={this.isSelected(user.uid)}
                        onDeletePress={onDelete}
                        currentUserId={currentUserId}
                        onEditUser={onEditUser}
                        handleClick={this.onClick}
                        showCreatedDate={showCreatedDate}
                        ToolsComponent={ToolsComponent}
                        onToggleSuspendUser={onToggleSuspendUnsuspendUser}
                        onToggleResendInvite={onToggleResendInvite}
                        customerId={this.props.customerId}
                        onRowHover={onHover}
                      />
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>

          {!showEmptyTableComponent && (
            <TablePagination
              rowsPerPageOptions={[]}
              count={users.length}
              rowsPerPage={rowsPerPage}
              component={"div"}
              page={page}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) => (
                <span className={styles.counter}>
                  {`USERS ${from} TO ${to} (OF ${count})`}
                </span>
              )}
              className={styles.pagination}
              classes={{ spacer: styles.paginationSpacer }}
            />
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDelete: (uid, user) => dispatch(onUserDeletePress(uid, user)),
  onMultipleUsersSuspend: (ids, accountSuspended) =>
    dispatch(multipleSuspendUnsuspend(ids, accountSuspended)),
  onMultipleUsersDelete: (ids) => dispatch(multipleDeletePress(ids)),
  onToggleSuspendUnsuspendUser: (id, accountSuspended, user) =>
    dispatch(onToggleSuspendUnsuspendUser(id, accountSuspended, user)),
  onToggleResendInvite: (id) => dispatch(onToggleResendInviteClick(id)),
});

CustomizedTables.propTypes = {
  users: PropTypes.array,
  customerId: PropTypes.string,
  currentUserId: PropTypes.string,
  activeFilter: PropTypes.string,
  showEmptyTableHeader: PropTypes.bool,
  emptyTableComponent: PropTypes.node,
  showCreatedDate: PropTypes.bool,
  ToolsComponent: PropTypes.any.isRequired,
  onDelete: PropTypes.func,
  onMultipleUsersSuspend: PropTypes.func,
  onMultipleUsersDelete: PropTypes.func,
  onToggleSuspendUnsuspendUser: PropTypes.func,
  onToggleResendInvite: PropTypes.func,
  onEditUser: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default compose(
  connect(null, mapDispatchToProps),
  withTrackProps(() => ({
    onDelete: () => HOME_PAGE_ANALYTICS.DELETE_USER,
    onMultipleUsersSuspend: (ids, accountSuspended) => {
      if (accountSuspended) {
        return HOME_PAGE_ANALYTICS.CLICK_UNSUSPEND_SELECTED_USERS;
      }
      return HOME_PAGE_ANALYTICS.CLICK_SUSPEND_SELECTED_USERS;
    },
    onMultipleUsersDelete: () =>
      HOME_PAGE_ANALYTICS.CLICK_DELETE_SELECTED_USERS,
    onToggleSuspendUnsuspendUser: (id, accountSuspended) => {
      if (accountSuspended) {
        return HOME_PAGE_ANALYTICS.UNSUSPEND_USER;
      }
      return HOME_PAGE_ANALYTICS.SUSPEND_USER;
    },
    onToggleResendInvite: () => HOME_PAGE_ANALYTICS.CLICK_RESEND_USER_INVITE,
    onEditUser: () => HOME_PAGE_ANALYTICS.EDIT_USER,
    onSelectAll: () => HOME_PAGE_ANALYTICS.CLICK_CHECKBOX__SELECT_ALL,
    onClick: () => HOME_PAGE_ANALYTICS.CLICK_CHECKBOX__SELECT_USER,
    onHover: () => HOME_PAGE_ANALYTICS.HOVER,
  }))
)(CustomizedTables);
