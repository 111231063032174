import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";

import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import styles from "./CreateUser.module.scss";
import BottomButtons from "../../../../components/BottomButtons/BottomButtons";
import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { CREATE_DPD_USER_ANALYTICS } from "../../../../constants/analytics";
import { CREATE_DPD_USER_FORM } from "../../../../constants/forms";
import { CREATE_DPD_USER } from "../../../../constants/strings";
import { onCreateDpdUser } from "../../../../features/user/user.actions";
import { validate } from "../../../../features/user/user.validate";
import { CREATE_PERMISSION_FIELD } from "../../../../features/userDetailsForm/userDetails/userDetails.constants";
import { DPD } from "../../../../router";
import { emailAsyncValidation } from "../../../../utils/commonValidation";
import CreateDpdUserForm from "../../components/CreateUserForm";

const CreateUser = ({
  handleSubmit,
  onCancelClick,
  onConfirmClick,
  onToggleChange,
}) => (
  <PageContainer>
    <Box className={styles.wrapper}>
      <Box>
        <GradientHeader title={CREATE_DPD_USER} />
        <CreateDpdUserForm onToggleChange={onToggleChange} />
      </Box>
      <Box className={styles.footer}>
        <BottomButtons
          onCancelClick={onCancelClick}
          onConfirmClick={handleSubmit(onConfirmClick)}
        />
      </Box>
    </Box>
  </PageContainer>
);

CreateUser.propTypes = {
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  onToggleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default compose(
  connect(null, (dispatch, props) => ({
    onConfirmClick: (formValues) => dispatch(onCreateDpdUser(formValues)),
    onCancelClick: () => props.history.push(DPD),
  })),
  reduxForm({
    form: CREATE_DPD_USER_FORM,
    validate,
    asyncValidate: emailAsyncValidation,
    asyncBlurFields: ["email"],
    enableReinitialize: true,
    initialValues: { isDpdAdmin: false, forename: "", lastname: "", email: "" },
  }),
  withTrack({
    loadId: CREATE_DPD_USER_ANALYTICS.LOAD,
    interfaceId: CREATE_DPD_USER_ANALYTICS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onConfirmClick: () => CREATE_DPD_USER_ANALYTICS.CONFIRM,
    onCancelClick: () => CREATE_DPD_USER_ANALYTICS.CANCEL,
    onToggleChange: ({ target: { name, value } }) => {
      let id = CREATE_DPD_USER_ANALYTICS.TOGGLE_CREATE_STAFF_OVERRIDE_USERS;
      if (name === CREATE_PERMISSION_FIELD) {
        id = CREATE_DPD_USER_ANALYTICS.TOGGLE_CREATE_DPD_USERS;
      }
      return createLogEntryPayload(id, {
        field: name,
        value,
      });
    },
  }))
)(CreateUser);
