import omit from "lodash/omit";
import { blur, change, SubmissionError } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  ACCOUNT_FIELD,
  SEARCH_RESULT,
  USERNAME_FIELD,
} from "./search.constants";
import {
  getInitialSearchFormValues,
  prepareSearchTableData,
  updateSingleUser,
} from "./search.models";
import { getSearchFormBusinessId, getSearchResults } from "./search.selectors";
import { DPD_SEARCH_FORM } from "../../constants/forms";
import { NO, YES } from "../../constants/strings";
import { formatMessage } from "../../utils/string";
import { createLoadingAction } from "../loader/loader.actions";
import { showModal } from "../modal/modal.actions";
import { SUSPEND, SUSPEND_THIS_USER, UNSUSPEND } from "../user/user.constants";
import { getAnalyticIds, isMigratedUser } from "../user/user.model";
import * as userService from "../user/user.service";

const ACTION_NAMESPACE = "SEARCH_USER";
const SEARCH_ERRORS = {
  username: "No users with this logon ID found",
  account: "No users exist for this account",
};

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SEARCH_USER: createAsyncActionTypes("SEARCH_USER"),
  CHECK_EXIST_INVITED_USERS: createAsyncActionTypes(
    "CHECK_EXIST_INVITED_USERS"
  ),
  DELETE_USER: createAsyncActionTypes("DELETE_USER"),
  DELETE_TEMP_USER: createAsyncActionTypes("DELETE_TEMP_USER"),
  SUSPEND_USER: createAsyncActionTypes("SUSPEND_USER"),
});

export const showSuspendUserModal =
  (updateUser) => (uid, accountSuspended) => (dispatch) => {
    const message = accountSuspended ? UNSUSPEND : SUSPEND;
    const contentText = formatMessage(SUSPEND_THIS_USER, message);

    dispatch(
      showModal({
        ...getAnalyticIds([uid], accountSuspended),
        contentText,
        cancelButtonText: NO,
        confirmButtonText: YES,
        onConfirmClick: () =>
          dispatch(updateUser(uid, { accountSuspended: !accountSuspended })),
      })
    );
  };

export const suspendUser = createLoadingAction(
  createAsyncAction(
    (uid, values) => (dispatch) =>
      userService.suspendUser(uid, values).then(() => {
        dispatch(suspendLocalUser(uid, values));
      }),
    ACTIONS.SUSPEND_USER
  )
);

export const onSuspendUnsuspendUserClick = showSuspendUserModal(suspendUser);

export const suspendLocalUser = (uid, values) => (dispatch, getState) => {
  const users = getSearchResults(getState());
  const updatedUsers = updateSingleUser(users, uid, values);
  dispatch(change(DPD_SEARCH_FORM, SEARCH_RESULT, updatedUsers));
};

export const checkExistInvitedUsers = createAsyncAction(
  createLoadingAction(
    ({ account, username, businessId }) =>
      async (dispatch) => {
        let exist;
        if (!account) {
          exist = false;
        } else {
          try {
            const result = await userService.getUserByUsernameAndAccount(
              {
                account: account.toLowerCase(),
                username: username.toLowerCase(),
              },
              businessId,
              true
            );
            exist = !!result.length;
          } catch (e) {
            exist = false;
          }
        }
        dispatch(change(DPD_SEARCH_FORM, "isExistInvitedUsers", exist));
      }
  ),
  ACTIONS.CHECK_EXIST_INVITED_USERS
);

export const onSearch = createAsyncAction(
  createLoadingAction(
    ({ username, account, businessId }, withTempUsers = false) =>
      async (dispatch) => {
        if (!username && !account) {
          throw new SubmissionError({ username: SEARCH_ERRORS.username });
        }

        dispatch(checkExistInvitedUsers({ username, account, businessId }));
        const searchResult = await userService.getUserByUsernameAndAccount(
          {
            account: account.toLowerCase(),
            username: username.toLowerCase(),
          },
          businessId,
          withTempUsers
        );
        const searchResultToShow = searchResult.filter((user) =>
          isMigratedUser(user)
        );

        if (!searchResultToShow.length && !withTempUsers) {
          const field = username ? USERNAME_FIELD : ACCOUNT_FIELD;
          throw new SubmissionError({ [field]: SEARCH_ERRORS[field] });
        }

        const formattedData = prepareSearchTableData(searchResultToShow);

        dispatch(change(DPD_SEARCH_FORM, "searchResults", formattedData));
        dispatch(change(DPD_SEARCH_FORM, "isTempUsers", withTempUsers));
        return formattedData;
      }
  ),
  ACTIONS.SEARCH_USER
);

export const resetUserSearchForm =
  (fields = {}) =>
  (dispatch) => {
    const resetValues = omit(getInitialSearchFormValues(), Object.keys(fields));

    Object.entries(resetValues).map(([field, value]) =>
      dispatch(change(DPD_SEARCH_FORM, field, value))
    );
  };

export const clearSearchField = (fieldName) => (dispatch, getState) => {
  const businessId = getSearchFormBusinessId(getState());
  dispatch(resetUserSearchForm({ businessId }));
  dispatch(change(DPD_SEARCH_FORM, fieldName, ""));
};

export const onSearchUsersClick =
  ({ username, account, businessId }, withTempUsers) =>
  (dispatch) => {
    const field = username ? USERNAME_FIELD : ACCOUNT_FIELD;
    dispatch(blur(DPD_SEARCH_FORM, field));
    return dispatch(onSearch({ username, account, businessId }, withTempUsers));
  };
