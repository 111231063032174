import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Table.module.scss";

export const StyledHeaderCell = ({ children, className, ...props }) => (
  <TableCell {...props} className={classNames(styles.headerCell, className)}>
    {children}
  </TableCell>
);

StyledHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const StyledTableCell = ({ children, cellStyles, ...props }) => (
  <TableCell
    {...props}
    className={classNames(styles.cell, cellStyles && cellStyles)}
  >
    {children}
  </TableCell>
);

StyledTableCell.propTypes = {
  field: PropTypes.any,
  children: PropTypes.node,
  cellStyles: PropTypes.string,
};

const SimpleTable = ({
  input,
  headerRows,
  renderItem,
  cellStyles,
  rowStyles,
  selectedStyles,
  onRowHover,
  noValueText,
  selectedRows = [],
}) => {
  const renderHeaderRows = () =>
    headerRows.map((item) => (
      <StyledHeaderCell key={item}>{item}</StyledHeaderCell>
    ));

  const renderNoValue = () => {
    if (noValueText && (!input.value || !input.value.length)) {
      return (
        <TableRow className={classNames(rowStyles)} onMouseEnter={onRowHover}>
          <StyledTableCell cellStyles={classNames(cellStyles)} colSpan={3}>
            {noValueText}
          </StyledTableCell>
        </TableRow>
      );
    }
    return null;
  };

  const renderRows = () =>
    input.value.map((data) => {
      const { id, fields } = data;
      const selected = selectedRows.includes(id);
      return (
        <TableRow
          key={id}
          className={classNames(rowStyles, selected && selectedStyles)}
          onMouseEnter={onRowHover}
        >
          {Object.values(fields).map((field, index) => (
            <StyledTableCell
              key={id + index}
              cellStyles={classNames(cellStyles)}
            >
              {renderItem ? renderItem(field, index, id, data) : field}
            </StyledTableCell>
          ))}
        </TableRow>
      );
    });

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>{renderHeaderRows()}</TableRow>
      </TableHead>
      <TableBody>
        {renderRows()}
        {renderNoValue()}
      </TableBody>
    </Table>
  );
};

SimpleTable.propTypes = {
  headerRows: PropTypes.array,
  selectedRows: PropTypes.array,
  input: PropTypes.object,
  renderItem: PropTypes.func,
  cellStyles: PropTypes.string,
  rowStyles: PropTypes.string,
  selectedStyles: PropTypes.string,
  noValueText: PropTypes.string,
  onRowHover: PropTypes.func,
};

export default SimpleTable;
