import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import {
  INCLUDE_COLLECTIONS_BY_OTHER_INFO,
  INCLUDE_COLLECTIONS_RAISED_BY_OTHERS,
  SHARE_MY_COLLECTION,
  SHARE_MY_COLLECTION_INFO,
} from "../../constants/strings";
import {
  INCLUDE_COLLECTIONS,
  SHARE_COLLECTIONS_FIELD,
} from "../../features/userDetailsForm/miscellaneousConfiguration/miscellaneousConfiguration.constants";
import CustomSwitchWithTitle from "../SwitchWidthTitle/CustomSwitchWithTitle";
import styles from "./Collections.module.scss";

export const Collections = ({ onInputChange, onInfoMouseOver }) => (
  <Grid container justify="space-between" className={styles.content}>
    <Field
      name={SHARE_COLLECTIONS_FIELD}
      component={CustomSwitchWithTitle}
      type="checkbox"
      onInfoMouseOver={onInfoMouseOver}
      label={SHARE_MY_COLLECTION}
      infoLabel={SHARE_MY_COLLECTION_INFO}
      onChange={(event, value) => {
        onInputChange(SHARE_COLLECTIONS_FIELD, value);
      }}
    />
    <Field
      name={INCLUDE_COLLECTIONS}
      component={CustomSwitchWithTitle}
      type="checkbox"
      onInfoMouseOver={onInfoMouseOver}
      label={INCLUDE_COLLECTIONS_RAISED_BY_OTHERS}
      infoLabel={INCLUDE_COLLECTIONS_BY_OTHER_INFO}
      onChange={(event, value) => {
        onInputChange(INCLUDE_COLLECTIONS, value);
      }}
    />
  </Grid>
);

Collections.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInfoMouseOver: PropTypes.func.isRequired,
};

export default Collections;
