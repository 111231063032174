import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const findByParams = (params) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CUSTOMER}/migration`,
      query: params,
    })
    .then((res) => res.data);

export const syncAppVersion = (accounts = [], updateVersion = "") =>
  webApi
    .put({
      path: `${API_ENDPOINTS.CUSTOMER}/version`,
      body: {
        accounts,
        updateVersion,
      },
    })
    .then((res) => res.data);
