import PropTypes from "prop-types";
import { compose } from "recompose";

import { withTrack } from "@dpdgroupuk/react-event-tracker";

import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import { CUSTOMER_MIGRATIONS_ANALYTICS } from "../../../../constants/analytics";
import { SEARCH } from "../../../../constants/strings";
import SearchForm from "./SearchForm";

const Migrations = () => (
  <PageContainer>
    <GradientHeader title={SEARCH} />
    <SearchForm />
  </PageContainer>
);

Migrations.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(
  withTrack({
    loadId: CUSTOMER_MIGRATIONS_ANALYTICS.LOAD,
    interfaceId: CUSTOMER_MIGRATIONS_ANALYTICS.INTERFACE_ID,
  })
)(Migrations);
