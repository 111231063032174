import { get, intersectionWith } from "lodash";

import {
  ACCOUNT_ID_FIELD,
  ADDLINE_1_FIELD,
  ADDLINE_2_FIELD,
  ADDLINE_3_FIELD,
  ADDLINE_4_FIELD,
  COMPANY_FIELD,
  COUNTRY_FIELD,
  EOD_MASTER_AVAILABLE_FIELD,
  EOD_MASTER_USER_FIELD,
  NOTIFICATION_EMAIL_FIELD,
  NOTIFICATION_NUMBER_FIELD,
  POSTCODE_FIELD,
} from "./accountDetails/accountDetails.constants";
import {
  formatCountry,
  prepareAccountDetails,
} from "./accountDetails/accountDetails.models";
import { prepareAdditionalAccounts } from "./additionalAccounts/additionalAccounts.models";
import { ADVANCED_CUSTOMER_CONFIGURATION_FIELDS } from "./advancedCustomerConfiguration/advancedCustomerConfiguration.constants";
import { prepareAdvancedCustomerConfiguration } from "./advancedCustomerConfiguration/advancedCustomerConfiguration.models";
import { getIpRestrictionsInitialValue } from "./ipRestriction/ipRestrictions.model";
import {
  ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
  INCLUDE_COLLECTIONS,
  INCLUDE_SHIPPING_BY_OTHERS_FIELD,
  RESTRICT_REDELIVER_FIELD,
  RESTRICT_UPGRADE_ACTIONS_FIELD,
  SHARE_COLLECTIONS_FIELD,
  SHARE_SHIPMENTS_BY_PROFILE_FIELD,
  SHARE_SHIPMENTS_FIELD,
  USE_JAVA_APPLET_FIELD,
} from "./miscellaneousConfiguration/miscellaneousConfiguration.constants";
import { prepareMiscellaneousConfiguration } from "./miscellaneousConfiguration/miscellaneousConfiguration.models";
import { SECURITY_SETTINGS_FIELDS } from "./securitySettings/securitySettings.constants";
import { prepareSecuritySettingsConfiguration } from "./securitySettings/securitySettings.model";
import { prepareServiceExclusions } from "./serviceExclusions/ServiceExclusions.models";
import { prepareServiceRestrictions } from "./serviceRestrictions/serviceRestrictions.model";
import { prepareXrefMappings } from "./serviceXrefMappings/serviceXrefMappings.model";
import {
  EOD_DAY_FIELD,
  EOD_MASTER_FIELD,
  EOD_TIME_FIELD,
  EOD_TOGGLES_FIELDS,
  EOD_USER_FIELD,
  ON_SCREEN_IMPORT_FIX_FIELD,
  SENDERS_REF1_FIELD,
  SHIPPINGS_SETTINGS_FIELDS,
  USE_BAD_FILE_FIELD,
  USE_SCREEN_FIELD,
} from "./shippingSettings/shippingSettings.constants";
import { prepareShippingSetting } from "./shippingSettings/shippingSettings.models";
import {
  CHANGE_DELIVERY_FIELD,
  COLLECTIONS_SHIPEASE_FIELD,
  DELIVERY_EXCEPTIONS_FIELD,
  DISCOLLECT_FIELD,
  EXREPORTS_FIELD,
  IEEXCEPTIONS_ROLE,
  PLREPORTS_FIELD,
  RESTRICT_ACTIONS_ROLE,
  RFI_ROLE,
} from "./systemAccess/systemAccess.constants";
import {
  prepareCustomerAdminAccounts,
  prepareSystemAccess,
} from "./systemAccess/systemAccess.models";
import {
  ACCOUNTS_FIELD,
  ADMIN_ACCESS_FIELD,
  CHANGE_MESSAGE_FIELD,
  FORENAME_FIELD,
  HOMECALL_REFERENCE,
  INCLUDE_CUSTOMER_CODE_FIELD,
  INCLUDE_REFERENCE_AS_BARCODE,
  INCLUDE_REFERENCE_CUSTOMER_ZONE,
  INCLUDE_TRACKING_BARCODE_FIELD,
  LASTNAME_FIELD,
  PASSWORD_LOCK_FIELD,
  TITLE_VALUES,
  USE_6_INCH_LABEL_FIELD,
  USE_ROAD_FIELD,
  USERNAME_FIELD,
} from "./userDetails/userDetails.constants";
import { prepareUserDetails } from "./userDetails/userDetails.models";
import {
  preparedUserProfiles,
  prepareToSaveUserProfiles,
} from "./userProfiles/userProfiles.models";
import { USER_FORMS } from "../../constants/forms";
import {
  ACCOUNT_EXPIRY_DATE_FIELD,
  USE_EMAIL_AS_LOGIN_FIELD,
} from "../myDpdUserForm/userDetails/userDetails.constants";
import { isCustomerAdmin, isDpdBusinessId } from "../user/user.model";

const createFormMappers = {
  [USER_FORMS.DPD_USER_DETAILS]: prepareUserDetails,
  [USER_FORMS.ACCOUNT_DETAILS]: prepareAccountDetails,
  [USER_FORMS.ADDITIONAL_ACCOUNTS]: prepareAdditionalAccounts,
  [USER_FORMS.SYSTEM_ACCESS]: prepareSystemAccess,
  [USER_FORMS.SYSTEM_ACCESS_ACCOUNTS]: prepareCustomerAdminAccounts,
  [USER_FORMS.MISCELLANEOUS_CONFIGURATION]: prepareMiscellaneousConfiguration,
  [USER_FORMS.ADVANCED_CUSTOMER_CONFIGURATION]:
    prepareAdvancedCustomerConfiguration,
  [USER_FORMS.SHIPPING_SETTINGS]: prepareShippingSetting,
  [USER_FORMS.SECURITY_SETTINGS]: prepareSecuritySettingsConfiguration,
  [USER_FORMS.SERVICE_EXCLUSIONS]: prepareServiceExclusions,
  [USER_FORMS.XREF_MAPPINGS]: prepareXrefMappings,
  [USER_FORMS.SERVICE_RESTRICTIONS]: prepareServiceRestrictions,
  [USER_FORMS.USER_PROFILES]: prepareToSaveUserProfiles,
};

const isExistRoles = (role, userInfo) => {
  if (userInfo && userInfo.appRoles && userInfo.appRoles.length) {
    return userInfo.appRoles.includes(role.toLowerCase());
  }
  return false;
};

export const prepareUserDetailsData = (formsData, { businessId, account }) =>
  Object.keys(formsData).reduce(
    (userDetails, form) => {
      const formMapper = createFormMappers[form];
      const formValues = formsData[form];

      let params;

      if (!formValues) {
        return userDetails;
      }
      switch (form) {
        case USER_FORMS.DPD_USER_DETAILS:
        case USER_FORMS.ADDITIONAL_ACCOUNTS: {
          params = {
            account: userDetails.account,
          };
          break;
        }
        case USER_FORMS.SYSTEM_ACCESS: {
          params = {
            account: userDetails.account,
            businessId: userDetails.businessId,
          };
          break;
        }
        case USER_FORMS.MISCELLANEOUS_CONFIGURATION: {
          params = {
            appRoles: userDetails.appRoles || [],
          };
          break;
        }
      }

      const formData =
        (formMapper && formMapper(formValues, params)) || formValues;
      return { ...userDetails, ...formData };
    },
    { businessId, account }
  );

export const getUserProfileInitialValue = ({ userInfo, profiles }) => {
  const unallocated = preparedUserProfiles(profiles);
  const userProfiles = get(userInfo, "userProfile", []);
  const allocated = intersectionWith(
    unallocated,
    userProfiles,
    ({ code }, profileCode) => code === profileCode
  );

  return {
    allocated,
    unallocated,
  };
};

export const getServiceExclusionsInitialValue = ({
  userInfo,
  dpdServices,
  dpdLocalServices,
}) => {
  const getService = (service) =>
    get(userInfo, "serviceExclusions", []).reduce((acc, code) => {
      const serviceData = service.find((service) => service.code === code);
      if (serviceData) {
        acc.push({
          code,
          name: serviceData.name,
          ...serviceData,
        });
      }
      return acc;
    }, []);

  return {
    dpd: getService(dpdServices),
    dpdLocal: getService(dpdLocalServices),
  };
};

export const getServiceRestrictionInitialValue = ({
  userInfo,
  serviceRestrictions,
  serviceRestrictionHelper,
}) => ({
  serviceRestricted: get(userInfo, "serviceRestricted", []).map((item) => ({
    postcode: item.postcode,
    selectValues: serviceRestrictions,
    restriction: item.restriction,
    restrictionName: serviceRestrictions.find(
      (restriction) => restriction.restrictionCode === item.restriction
    ),
    restrictionCode:
      serviceRestrictionHelper.find(
        (restriction) => restriction.postcode === item.postcode
      ) || item.postcode,
  })),
  mapping: "",
});

export const getServiceXrefMappingsInitialValue = ({
  userInfo,
  dpdServices,
  dpdLocalServices,
}) => {
  const selectValues = isDpdBusinessId(userInfo.businessId)
    ? dpdServices
    : dpdLocalServices;
  const serviceXrefMappings = get(userInfo, "serviceXrefMappings", []).map(
    (item) => ({
      mappingText: item.mappingText,
      selectValues,
      serviceCode: item.serviceCode,
    })
  );

  return {
    serviceXrefMappings,
    mapping: "",
  };
};

export const getShippingSettingsInitialValue = ({ userInfo }) => {
  const getShippingFieldValue = (field, defaultValue = 0) =>
    Number(get(userInfo, `shippingSettings.${field}`, defaultValue));
  const onScreenImport = getShippingFieldValue(ON_SCREEN_IMPORT_FIX_FIELD);
  const initialValues = {
    [USE_BAD_FILE_FIELD]: 1 - onScreenImport,
    [USE_SCREEN_FIELD]: onScreenImport,
    [SENDERS_REF1_FIELD]: getShippingFieldValue(SENDERS_REF1_FIELD, false),
  };

  SHIPPINGS_SETTINGS_FIELDS.forEach((field) => {
    initialValues[field] = getShippingFieldValue(field);
  });

  return { ...initialValues, ...getEndOfDayInitialValue({ userInfo }) };
};

export const getEndOfDayInitialValue = ({ userInfo }) => {
  const initialValues = {
    [EOD_USER_FIELD]: get(
      userInfo,
      `shippingSettings.${EOD_USER_FIELD}`,
      false
    ),
    [EOD_DAY_FIELD]: get(
      userInfo,
      `shippingSettings.${EOD_DAY_FIELD}`,
      "1,2,3,4,5"
    )
      .split(",")
      .map(Number),
    [EOD_TIME_FIELD]: get(userInfo, `shippingSettings.${EOD_TIME_FIELD}`, ""),
    [EOD_MASTER_FIELD]: Number(
      get(userInfo, `shippingSettings.${EOD_MASTER_FIELD}`, 0)
    ),
  };

  return EOD_TOGGLES_FIELDS.reduce((acc, item) => {
    acc[item] = Number(get(userInfo, `shippingSettings.${item}`, "0"));
    return acc;
  }, initialValues);
};

export const getMiscellaneousConfigurationInitialValue = ({ userInfo }) => {
  const fieldArrayNumber = [
    ALLOW_EDIT_SHIPMENTS_BY_PROFILE_FIELD,
    INCLUDE_SHIPPING_BY_OTHERS_FIELD,
    SHARE_COLLECTIONS_FIELD,
    INCLUDE_COLLECTIONS,
    RESTRICT_REDELIVER_FIELD,
  ];

  let isRestrict = false;
  if (userInfo && userInfo.appRoles && userInfo.appRoles.length) {
    isRestrict = userInfo.appRoles.includes(RESTRICT_ACTIONS_ROLE);
  }

  const initialValues = {
    [RESTRICT_UPGRADE_ACTIONS_FIELD]: isRestrict,
    [USE_JAVA_APPLET_FIELD]: get(
      userInfo,
      `miscellaneousConfiguration.${USE_JAVA_APPLET_FIELD}`,
      false
    ),
  };

  const shareShipments = Number(
    get(userInfo, `miscellaneousConfiguration.${SHARE_SHIPMENTS_FIELD}`, 0)
  );

  if (shareShipments === 2) {
    initialValues[SHARE_SHIPMENTS_BY_PROFILE_FIELD] = true;
    initialValues[SHARE_SHIPMENTS_FIELD] = false;
  } else {
    initialValues[SHARE_SHIPMENTS_BY_PROFILE_FIELD] = false;
    initialValues[SHARE_SHIPMENTS_FIELD] = Boolean(shareShipments);
  }

  fieldArrayNumber.forEach((field) => {
    initialValues[field] = Number(
      get(userInfo, `miscellaneousConfiguration.${field}`, 0)
    );
  });

  return initialValues;
};

export const getSystemAccessInitialValue = ({ userInfo }) => ({
  [CHANGE_DELIVERY_FIELD]: isExistRoles(CHANGE_DELIVERY_FIELD, userInfo),
  [COLLECTIONS_SHIPEASE_FIELD]: isExistRoles(
    COLLECTIONS_SHIPEASE_FIELD,
    userInfo
  ),
  [DELIVERY_EXCEPTIONS_FIELD]:
    isExistRoles(IEEXCEPTIONS_ROLE, userInfo) ||
    isExistRoles(RFI_ROLE, userInfo),
  [DISCOLLECT_FIELD]: isExistRoles(DISCOLLECT_FIELD, userInfo),
  [EXREPORTS_FIELD]: isExistRoles(EXREPORTS_FIELD, userInfo),
  [PLREPORTS_FIELD]: isExistRoles(PLREPORTS_FIELD, userInfo),
});

export const getSecuritySettingsInitialValue = ({ userInfo }) => {
  const initialValues = {};

  SECURITY_SETTINGS_FIELDS.forEach((field) => {
    initialValues[field] = get(userInfo, `securitySettings.${field}`, false);
  });

  return initialValues;
};

export const getAdvancedCustomerConfigurationInitialValue = ({ userInfo }) => {
  const initialValues = {};

  ADVANCED_CUSTOMER_CONFIGURATION_FIELDS.forEach((field) => {
    initialValues[field] = Boolean(
      get(userInfo, `advancedCustomerConfiguration.${field}`, false)
    );
  });

  return initialValues;
};

export const getAccountDetailsInitialValue = ({ userInfo, customer }) => {
  const customerField = "customerAddress";
  const getField = (field, customerFields) =>
    overrideDetails
      ? get(userInfo, `${customerField}.${field}`)
      : get(customer, customerFields || field, "");
  const overrideDetails = !!get(userInfo, `${customerField}.${POSTCODE_FIELD}`);
  return {
    businessId: userInfo.businessId,
    [ACCOUNT_ID_FIELD]: get(userInfo, ACCOUNT_ID_FIELD, ""),
    [COUNTRY_FIELD]: formatCountry(getField(COUNTRY_FIELD)),
    overrideDetails,
    [POSTCODE_FIELD]:
      getField(POSTCODE_FIELD) || get(customer, [POSTCODE_FIELD], ""),
    [ADDLINE_1_FIELD]: getField(ADDLINE_1_FIELD),
    [ADDLINE_3_FIELD]: getField(ADDLINE_3_FIELD),
    [ADDLINE_2_FIELD]: getField(ADDLINE_2_FIELD),
    [ADDLINE_4_FIELD]: getField(ADDLINE_4_FIELD),
    accountExpiry: get(userInfo, "accountExpiry", ""),
    [COMPANY_FIELD]: get(customer, [COMPANY_FIELD], ""),
    [NOTIFICATION_EMAIL_FIELD]: getField(
      NOTIFICATION_EMAIL_FIELD,
      "notifyemail"
    ),
    [NOTIFICATION_NUMBER_FIELD]: getField(
      NOTIFICATION_NUMBER_FIELD,
      "notifysms"
    ),
    eodMasterAvailable: get(customer, EOD_MASTER_AVAILABLE_FIELD, false),
    eodMasterUser: get(customer, EOD_MASTER_USER_FIELD),
    customerId: get(customer, "uid"),
  };
};

export const getCommonUserDetailsInitialValue = ({ userInfo }) => ({
  uid: userInfo.uid,
  [USERNAME_FIELD]: get(userInfo, USERNAME_FIELD, ""),
  [ACCOUNTS_FIELD]: get(userInfo, ACCOUNTS_FIELD, []),
  [LASTNAME_FIELD]: get(userInfo, LASTNAME_FIELD, ""),
  email: get(userInfo, "email", ""),
  [FORENAME_FIELD]: get(userInfo, FORENAME_FIELD, ""),
  [ADMIN_ACCESS_FIELD]: isCustomerAdmin(userInfo),
  [PASSWORD_LOCK_FIELD]: Boolean(get(userInfo, PASSWORD_LOCK_FIELD, false)),
  newPassword: "",
  reEnterPassword: "",
  [INCLUDE_REFERENCE_CUSTOMER_ZONE]: get(
    userInfo,
    INCLUDE_REFERENCE_CUSTOMER_ZONE,
    "1"
  ),
  [INCLUDE_REFERENCE_AS_BARCODE]: get(
    userInfo,
    INCLUDE_REFERENCE_AS_BARCODE,
    "1"
  ),
  [HOMECALL_REFERENCE]: get(userInfo, HOMECALL_REFERENCE, "0"),
  [USE_ROAD_FIELD]: Number(get(userInfo, USE_ROAD_FIELD, 0)),
  [USE_6_INCH_LABEL_FIELD]: Boolean(
    get(userInfo, USE_6_INCH_LABEL_FIELD, false)
  ),
  [INCLUDE_CUSTOMER_CODE_FIELD]: get(
    userInfo,
    INCLUDE_CUSTOMER_CODE_FIELD,
    false
  ),
  [INCLUDE_TRACKING_BARCODE_FIELD]: get(
    userInfo,
    INCLUDE_TRACKING_BARCODE_FIELD,
    false
  ),
});

export const getEmailAsLoginValue = (userInfo) =>
  userInfo.email === userInfo.username;

export const getMyDPDUserDetailsInitialValue = ({ userInfo }) => ({
  ...getCommonUserDetailsInitialValue({ userInfo }),
  [USE_EMAIL_AS_LOGIN_FIELD]: getEmailAsLoginValue(userInfo),
  [ACCOUNT_EXPIRY_DATE_FIELD]: get(userInfo, ACCOUNT_EXPIRY_DATE_FIELD, null),
});

const getTitle = (userInfo) => {
  const title = get(userInfo, "title", TITLE_VALUES[0]).toLowerCase();
  if (TITLE_VALUES.includes(title)) {
    return title;
  }
  return TITLE_VALUES[0];
};

export const getUserDetailsInitialValue = ({ userInfo }) => ({
  ...getCommonUserDetailsInitialValue({ userInfo }),
  title: getTitle(userInfo),
  description: get(userInfo, "description", ""),
  position: get(userInfo, "position", ""),
  phone: get(userInfo, "phone", ""),
  [CHANGE_MESSAGE_FIELD]: get(userInfo, CHANGE_MESSAGE_FIELD, ""),
});

export const getAdditionalAccountsDefaultValues = ({
  additionalAccounts,
  userInfo: { account: accountId },
}) => ({
  accounts: additionalAccounts.filter(({ account }) => account !== accountId),
});

export const getCustomerAdminAccountsValues = ({ customerAdminAccounts }) => ({
  accounts: customerAdminAccounts,
});

const defaultUpdateMappers = () => ({
  [USER_FORMS.SYSTEM_ACCESS]: getSystemAccessInitialValue,
  [USER_FORMS.MISCELLANEOUS_CONFIGURATION]:
    getMiscellaneousConfigurationInitialValue,
  [USER_FORMS.ADVANCED_CUSTOMER_CONFIGURATION]:
    getAdvancedCustomerConfigurationInitialValue,
  [USER_FORMS.SERVICE_EXCLUSIONS]: getServiceExclusionsInitialValue,
  [USER_FORMS.SECURITY_SETTINGS]: getSecuritySettingsInitialValue,
  [USER_FORMS.SHIPPING_SETTINGS]: getShippingSettingsInitialValue,
  [USER_FORMS.XREF_MAPPINGS]: getServiceXrefMappingsInitialValue,
  [USER_FORMS.SERVICE_RESTRICTIONS]: getServiceRestrictionInitialValue,
  [USER_FORMS.USER_PROFILES]: getUserProfileInitialValue,
  [USER_FORMS.SYSTEM_ACCESS_ACCOUNTS]: getCustomerAdminAccountsValues,
  [USER_FORMS.ADDITIONAL_ACCOUNTS]: getAdditionalAccountsDefaultValues,
});

export const updateFormMapper = (isCustomerAdmin) => {
  let initial = {
    [USER_FORMS.DPD_USER_DETAILS]: getUserDetailsInitialValue,
    [USER_FORMS.ACCOUNT_DETAILS]: getAccountDetailsInitialValue,
  };

  if (isCustomerAdmin) {
    initial = {
      [USER_FORMS.DPD_USER_DETAILS]: getMyDPDUserDetailsInitialValue,
      [USER_FORMS.IP_RESTRICTIONS]: getIpRestrictionsInitialValue,
    };
  }

  return {
    ...defaultUpdateMappers(),
    ...initial,
  };
};
