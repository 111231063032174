import {
  createAction,
  createActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { ACCOUNT_ACCESS_DENIED_MESSAGE, CLOSE } from "../../constants/strings";
import { ALIGN_BUTTONS, DELAY } from "./modal.constants";

const ACTION_NAMESPACE = "MODAL";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
});

export const showModal = (modalProps) =>
  createPayloadAction(ACTIONS.SHOW_MODAL, modalProps);

export const hideModal = () => createAction(ACTIONS.HIDE_MODAL);

export const showModalWithDelay =
  ({ ...modalProps }) =>
  (dispatch) =>
    setTimeout(() => {
      dispatch(
        showModal({
          ...modalProps,
        })
      );
    }, DELAY);

export const showInfoModal =
  (contentText, props = {}) =>
  (dispatch) =>
    dispatch(
      showModal({
        contentText,
        confirmButtonText: CLOSE,
        showCancelButton: false,
        alignButton: ALIGN_BUTTONS.CENTER,
        ...props,
      })
    );

export const showAccessDeniedModal = (onConfirmClick) => (dispatch) =>
  dispatch(
    showModal({
      contentText: ACCOUNT_ACCESS_DENIED_MESSAGE,
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
      onConfirmClick,
    })
  );
