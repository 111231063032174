import { SEARCH_PARAM_FIELD, SEARCH_PARAMS } from "./search.constants";

export const getInitialSearchFormValues = {
  [SEARCH_PARAM_FIELD]: SEARCH_PARAMS.ACCOUNT,
  searchResults: [],
};

export const updateSingleUser = (accountUsers, uid, values) =>
  accountUsers.map((item) => {
    if (item.id === uid) {
      return { ...item, ...values };
    }
    return item;
  });
