import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import { createSelector } from "reselect";

import { INITIAL_CURRENCY } from "../../constants/strings";
import {
  filterCurrency,
  filterExchangeServices,
  filterInternationalService,
  filterLocalOutboundServices,
  filterOutboundServices,
} from "./config.models";

export const getConfig = (state) => state.config;

export const getCurrencies = createSelector(
  getConfig,
  (config) => config.currencies
);

export const getDpdServices = createSelector(
  getConfig,
  (config) => config.dpdServices
);

export const getDpdAppVersions = createSelector(
  getConfig,
  (config) => config.appVersions
);

export const getDpdLocalServices = createSelector(
  getConfig,
  (config) => config.dpdLocalServices
);

export const getServiceRestrictionHelper = createSelector(
  getConfig,
  (config) => config.serviceRestrictionHelper
);

export const getServiceRestrictions = createSelector(
  getConfig,
  (config) => config.serviceRestrictions
);

export const getZeroRestrictionItems = createSelector(
  getServiceRestrictions,
  (serviceRestrictions) =>
    filter(
      serviceRestrictions,
      (restriction) => `${restriction.restrictionCode}` === "0"
    )
);

export const getIrishCodes = createSelector(
  getServiceRestrictionHelper,
  (serviceRestrictions) =>
    filter(serviceRestrictions, (restriction) => restriction.type === "I")
);

export const getOffshoreCodes = createSelector(
  getServiceRestrictionHelper,
  (serviceRestrictions) =>
    filter(serviceRestrictions, (restriction) => restriction.type === "O")
);

export const getHighlandsAndIslandCodes = createSelector(
  getServiceRestrictionHelper,
  (serviceRestrictions) =>
    filter(serviceRestrictions, (restriction) => restriction.type === "H")
);

export const getOutboundDpdServices = createSelector(
  getDpdServices,
  (services) =>
    sortBy(filterOutboundServices(services), (service) => [
      service.sort,
      service.name,
    ])
);

export const getExchangeDpdServices = createSelector(
  getDpdServices,
  (services) =>
    sortBy(filterExchangeServices(services), (service) => [
      service.sort,
      service.name,
    ])
);

export const getInternationalServices = createSelector(
  getDpdServices,
  (services) =>
    sortBy(filterInternationalService(services), (service) => [
      service.sort,
      service.name,
    ])
);

export const getDpdLocalOutboundServices = createSelector(
  getDpdLocalServices,
  (services) =>
    sortBy(filterLocalOutboundServices(services), (service) => [
      service.sort,
      service.name,
    ])
);

export const getDpdLocalInternationalServices = createSelector(
  getDpdLocalServices,
  (services) =>
    sortBy(filterInternationalService(services), (service) => [
      service.sort,
      service.name,
    ])
);

export const getServiceExclusionsDpdServices = createSelector(
  getDpdServices,
  (services) => {
    const serviceList = services.filter(
      ({ serviceExclusions }) => serviceExclusions
    );
    return sortBy(serviceList, (service) => service.name);
  }
);

export const getServiceExclusionsDpdLocalServices = createSelector(
  getDpdLocalServices,
  (services) => {
    const serviceList = services.filter(
      ({ serviceExclusions }) => serviceExclusions
    );
    return sortBy(serviceList, (service) => service.name);
  }
);

export const getFilteredCurrencies = createSelector(
  getCurrencies,
  (currencies) => {
    const result = filterCurrency(currencies);
    return [INITIAL_CURRENCY, ...result];
  }
);
