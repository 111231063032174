import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router";

import ActivityMonitor from "./pages/activity-monitor/ActivityMonitor";
import CreateUserPage from "./pages/create";
import EditUserPage from "./pages/edit";
import CustomerMenu from "../../components/Layout/components/CustomerMenu";
import Layout from "../../components/Layout/Layout";
import TeamSettings from "../../pages/user/pages/team-settings/TeamSettings.js";
import {
  CURRENT_CUSTOMER,
  CUSTOMER_ACTIVITY_MONITOR,
  CUSTOMER_CREATE_USER_PAGE,
  CUSTOMER_EDIT_USER_PAGE,
  CUSTOMER_TEAM_SETTINGS_PAGE,
  CUSTOMER_USERS_PAGE,
  PAGE_404,
} from "../../router";
import ManageUsers from "../main/ManageUsers";

export const CustomerNestedRoutes = () => (
  <Layout MenuComponent={CustomerMenu}>
    <Switch>
      <Redirect from={CURRENT_CUSTOMER} exact to={CUSTOMER_USERS_PAGE} />
      <Route
        path={CUSTOMER_TEAM_SETTINGS_PAGE}
        exact
        component={TeamSettings}
      />
      <Route path={CUSTOMER_USERS_PAGE} exact component={ManageUsers} />
      <Route
        path={CUSTOMER_CREATE_USER_PAGE}
        exact
        component={CreateUserPage}
      />
      <Route
        path={CUSTOMER_ACTIVITY_MONITOR}
        exact
        component={ActivityMonitor}
      />
      <Route path={CUSTOMER_EDIT_USER_PAGE} exact component={EditUserPage} />
      <Redirect exact to={PAGE_404} />
    </Switch>
  </Layout>
);

CustomerNestedRoutes.propTypes = {
  match: PropTypes.object,
};
