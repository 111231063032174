import get from "lodash/get";
import { createSelector } from "reselect";

import {
  canAddDpdUsers,
  getFilteredUsersByName,
  getManageUserList,
  getUsersByActiveFilter,
  isCanCreateMasterUser,
  isDpdAdmin,
} from "./user.model";

export const getAccountUsers = (state) => state.user.accountUsers;
export const getGeneratedUsernames = (state) => state.user.usernames;

export const getFilteredAccountUsers = createSelector(
  getAccountUsers,
  getManageUserList
);
export const getCurrentUser = (state) => state.user.currentUser;
export const shouldShowLeavePrompt = (state) => state.user.showPrompt;

export const getUserById = (id) =>
  createSelector(getAccountUsers, (users) =>
    users.find(({ uid }) => uid === id)
  );

export const getCustomerAccountId = createSelector(getCurrentUser, (user) =>
  get(user, "account", "")
);

export const getCurrentUserRoles = createSelector(getCurrentUser, (user) =>
  get(user, "userRoles", [])
);

export const getCurrentUserDisplayName = createSelector(
  getCurrentUser,
  (user) => get(user, "displayName", "")
);

export const getCurrentUserEmail = createSelector(getCurrentUser, (user) =>
  get(user, "email", "")
);

export const getCurrentUserUsername = createSelector(getCurrentUser, (user) =>
  get(user, "username", "")
);

export const isCurrentUserCanAddDpdUsers = createSelector(
  getCurrentUserRoles,
  (userRoles) => canAddDpdUsers({ userRoles })
);

export const isCurrentUserDpdAdmin = createSelector(
  getCurrentUserRoles,
  (userRoles) => isDpdAdmin({ userRoles })
);

export const isCurrentUserCanCreateMasterUser = createSelector(
  getCurrentUserRoles,
  (userRoles) => isCanCreateMasterUser({ userRoles })
);

export const getCustomerAdminAccounts = createSelector(getCurrentUser, (user) =>
  get(user, "customerAdminAccounts", [])
);

export const getUserBusinessId = createSelector(getCurrentUser, (user) =>
  get(user, "businessId", null)
);

export const getUserId = createSelector(getCurrentUser, (user) =>
  get(user, "uid", null)
);

export const getUsersFilteredByActiveFilter = (activeFilter) =>
  createSelector(getFilteredAccountUsers, (users) =>
    getUsersByActiveFilter(users, activeFilter)
  );

export const getUsersByFilterAndSearch = (search, activeFilter) =>
  createSelector(getUsersFilteredByActiveFilter(activeFilter), (users) =>
    getFilteredUsersByName(search, users)
  );
