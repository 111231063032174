import get from "lodash/get";
import isNil from "lodash/isNil";

import { ACTIONS } from "./userDetailsForm.actions";

export const initialState = {
  isOverrideEnabled: false,
  masterEodDetails: null,
  customerData: {},
  userInfo: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_USER_INFO.SUCCESS: {
      const { account, eodMasterUser, eodMasterAvailable, uid, ...customer } =
        get(action, "payload.customer", {});
      const userInfo = get(action, "payload.user", {});
      return {
        ...state,
        isOverrideEnabled: true,
        masterEodDetails: {
          // @see https://it.dpduk.live/it/diagram/diag_TDMzvo6C48kIGKjZ.html?id=1669108556422
          eodMasterAvailable: isNil(eodMasterAvailable) || eodMasterAvailable,
          eodMasterUser,
          customerId: uid,
          account,
        },
        customerData: customer,
        userInfo,
      };
    }
    case ACTIONS.CHANGE_ACCOUNT:
    case ACTIONS.PREPARE_CUSTOMER_DATA.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { account, eodMasterUser, eodMasterAvailable, uid, ...customer } =
        action.payload || {};

      return {
        ...state,
        isOverrideEnabled: true,
        masterEodDetails: {
          eodMasterAvailable: isNil(eodMasterAvailable) || eodMasterAvailable,
          eodMasterUser,
          customerId: uid,
          account,
        },
        customerData: customer,
      };
    case ACTIONS.CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};
