import { PureComponent } from "react";

import TableCell from "@mui/material/TableCell";
import classNames from "classnames";
import PropTypes from "prop-types";
import { AutoSizer, Column, Table } from "react-virtualized";

import tableStyles from "./table.module.scss";

class MuiVirtualizedTable extends PureComponent {
  static defaultProps = {
    headerHeight: 60,
    rowHeight: 60,
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns } = this.props;
    const style = { height: "100%", width: columns[columnIndex].width };
    if (columnIndex === 0) {
      style.borderLeft = "none";
    }

    if (columnIndex === columns.length - 1) {
      style.borderRight = "none";
    }
    return (
      <TableCell
        component="div"
        className={classNames(tableStyles.cell)}
        variant="body"
        style={style}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {cellData || " "}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { columns } = this.props;

    return (
      <TableCell
        component="div"
        variant="head"
        style={{
          borderBottom: "solid 1px #CCCCCC",
          fontSize: "15px",
          fontWeight: "normal",
          lineHeight: "normal",
          textAlign: "center",
          color: "#878787",
          height: "60px",
          display: "flex",
          paddingRight: "3px",
          paddingLeft: "3px",
          paddingTop: "16px",
          paddingBottom: "16px",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            {...tableProps}
            rowClassName={tableStyles.flexContainer}
          >
            {columns.map(({ dataKey, ...other }, index) => (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                  })
                }
                className={tableStyles.flexContainer}
                cellRenderer={this.cellRenderer}
                dataKey={dataKey}
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const calcHeight = (count, defaultHeight) => {
  const expectedHeight = count * 60 + 60;
  let height = defaultHeight;
  if (expectedHeight < defaultHeight) {
    height = expectedHeight;
  }
  return height;
};

export default function ReactVirtualizedTable({
  input,
  columns,
  defaultHeight = 650,
}) {
  const rows = input.value || [];
  return (
    <div
      style={{
        height: `${calcHeight(rows.length, defaultHeight)}px`,
        width: "100%",
      }}
    >
      <MuiVirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={columns}
      />
    </div>
  );
}

ReactVirtualizedTable.propTypes = {
  defaultHeight: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
  }),
};
