import { change, getFormValues, initialize } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import {
  SEARCH_PARAM_FIELD,
  SEARCH_PARAMS,
  SEARCH_RESULTS_FIELD,
  SEARCH_VALUE_FIELD,
  VERSION_FIELD,
} from "./search.constants";
import * as searchService from "./search.service";
import { CUSTOMER_MIGRATION_SEARCH_FORM } from "../../constants/forms";
import { DPD, DPD_LOCAL } from "../../constants/strings";
import { createLoadingAction } from "../loader/loader.actions";

const ACTION_NAMESPACE = "MIGRATION_SEARCH";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SEARCH_USERS: createAsyncActionTypes("SEARCH_USERS"),
  SYNC_USER_VERSION: createAsyncActionTypes("SYNC_USER_VERSION"),
  SEARCH_CUSTOMERS_BY_NAME: createAsyncActionTypes("SEARCH_CUSTOMERS_BY_NAME"),
});

export const clearSearchField = () => async (dispatch, getState) => {
  const state = getState();
  const values = getFormValues(CUSTOMER_MIGRATION_SEARCH_FORM)(state);
  return dispatch(
    initialize(CUSTOMER_MIGRATION_SEARCH_FORM, {
      [SEARCH_VALUE_FIELD]: "",
      [SEARCH_RESULTS_FIELD]: "",
      [SEARCH_PARAM_FIELD]: values[SEARCH_PARAM_FIELD]
        ? values[SEARCH_PARAM_FIELD]
        : SEARCH_PARAMS.ACCOUNT,
    })
  );
};

const onSearchByParams = createAsyncAction(
  createLoadingAction((params) => async (dispatch, getState) => {
    const state = getState();
    const results = await searchService.findByParams(params);
    const preparedResult = params.account
      ? results.userAccounts.map((data) => ({
          ...data,
          businessUnit:
            data.businessId === 1 ? DPD.toUpperCase() : DPD_LOCAL.toUpperCase(),
        }))
      : results.customerAccounts.map((data) => ({
          ...data,
          businessUnit:
            data.business === 1 ? DPD.toUpperCase() : DPD_LOCAL.toUpperCase(),
        }));
    const appVersions = state.config.appVersions;
    const latestVersion = appVersions.length
      ? appVersions[appVersions.length - 1].version
      : null;
    dispatch(
      change(CUSTOMER_MIGRATION_SEARCH_FORM, VERSION_FIELD, latestVersion)
    );
    dispatch(
      change(
        CUSTOMER_MIGRATION_SEARCH_FORM,
        SEARCH_RESULTS_FIELD,
        preparedResult
      )
    );
    return preparedResult;
  }),
  ACTIONS.SEARCH_USERS
);

export const onSearch = (values) => (dispatch) => {
  if (values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.ACCOUNT) {
    return dispatch(onSearchByParams({ account: values[SEARCH_VALUE_FIELD] }));
  } else if (values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.COMPANY_NAME) {
    return dispatch(
      onSearchByParams({ companyName: values[SEARCH_VALUE_FIELD] })
    );
  } else {
    const accounts = values[SEARCH_VALUE_FIELD].split(",");
    return dispatch(onSearchByParams({ accounts }));
  }
};

export const onSyncVersion = createAsyncAction(
  createLoadingAction(() => async (dispatch, getState) => {
    const accounts = [];
    const values = getFormValues(CUSTOMER_MIGRATION_SEARCH_FORM)(getState());

    if (values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.ACCOUNT) {
      accounts.push(values[SEARCH_VALUE_FIELD]);
    } else if (values[SEARCH_PARAM_FIELD] === SEARCH_PARAMS.COMPANY_NAME) {
      const customers = values[SEARCH_RESULTS_FIELD];
      customers.forEach((customer) => {
        accounts.push(customer.account);
      });
    } else {
      values[SEARCH_VALUE_FIELD].split(",").forEach((accountCode) => {
        accounts.push(accountCode.split("*")[0].trim());
      });
    }
    await searchService.syncAppVersion(accounts, values[VERSION_FIELD]);
    dispatch(clearSearchField());
  }),
  ACTIONS.SYNC_USER_VERSION
);
